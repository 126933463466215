<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">{{showLang('com.site')}} 【{{currentStation ? currentStation.name : ''}}】 {{showLang('com.lighting.leakage')}} 【{{item.name}}】 {{showLang('history.data')}}</div>
    <Form ref="form" :label-width="0">
      <FormItem :label="showLang('com.date.time')" :label-width="80">
        <div style="display: flex">
          <DatePicker type="datetime" v-model="filter.start" format="yyyy-MM-dd HH:mm" style="width: 155px; margin-right: 10px"></DatePicker>
          {{showLang('com.date.to')}}
          <DatePicker type="datetime" v-model="filter.end" format="yyyy-MM-dd HH:mm" style="width: 155px; margin-left: 10px"></DatePicker>
          <Button type="primary" style="margin-left: 10px" @click="search">{{showLang('com.op.query')}}</Button>
          <Button type="primary" style="margin-left: 10px" @click="exportData">{{showLang('com.op.export')}}</Button>
          <span style="margin-left: 20px">{{showLang('com.data.sum.record',history.length)}}</span>
        </div>
      </FormItem>
      <FormItem>
        <LineChart ref="lineChart1" :config="config" :id="`pb-branch-${item.id}`" style="width: 1168px;height: 300px" />
      </FormItem>
      <FormItem>
        <div class="branch-table">
          <table id="tabMainDataHistory" class="table">
            <tr>
              <td class="column-header" style="width: 200px">{{showLang('com.date.occurrence')}}</td>
              <td class="column-header" style="width:120px" >{{showLang('com.lighting.leakage.val')}}</td>
            </tr>
            <template v-for="(branch, index) in history">
              <tr :key="`data-${index}-0`">
                <td class="column-content">{{new Date(branch.fireTime).format('yyyy-MM-dd HH:mm:ss')}}</td>
                <td class="column-content">{{img.val(branch.content.cl, 2)}} mA</td>
              </tr>
            </template>
          </table>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
    </div>
  </Modal>
</template>
<script>
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import { mapGetters, mapState } from 'vuex'
import LineChart from '@/components/charts/LineChart'
export default {
  name: 'ModalLeakageData',
  components: {
    LineChart,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // device: {
    //   type: Object,
    //   default(){ return {};}
    // },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      filter: {
        start: '',
        end: '',
      },
      history: [],
      loading: false,
      config: {
        title: `漏电监测历史数据`
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        let now1 = new Date();
        now1.setDate(now1.getDate() - 7);
        this.filter.start = now1;
        this.filter.end = new Date();
        this.search();
        this.config.title=this.showLang('com.lighting.leakage')+this.showLang('history.data')
      }
    },
    device() {
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapState('cmd', ['singleCmdResult']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    currentStation: function () {
      let ss= this.selectedDevices.filter(p => p.id == this.item.stationId);
      if(ss.length == 0)return null;
      return ss[0];
    },
  },
  methods: {
    np: function(u, c, p){
      if(!u || !c || !p)return '--';
      if(u < 0 || c < 0 ||  p< 0)return '-';
      return (u * c - p).toFixed(2);
    },
    exportData: function () {
      var xlsxParam = { raw: true };//转换成excel时，使用原始的格式
      var wb = XLSX.utils.table_to_book(document.querySelector("#tabMainDataHistory"), xlsxParam);//outTable为列表id
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream;charset=utf-8" }),`${this.showLang('com.lighting.leakage.val')}.xlsx`
        );
      } catch (e) {
        // if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    currentStatus: function (params, type) {
      if (params.timeValue == 1 && params[`current${type}Value`] < (params[`current${type}Rated`] * params[`current${type}Low`] / 100)) return this.showLang('alarm.type.current.under')
      if (params.timeValue == 1 && params[`current${type}Value`] > (params[`current${type}Rated`] * params[`current${type}High`] / 100)) return this.showLang('alarm.type.current.over')
      return this.showLang('com.state.normal');
    },
    currentVStatus: function (params, type) {
      if (params.timeValue == 1 && params[`voltage${type}Value`] < (params[`voltage${type}Rated`] * params[`voltage${type}Low`] / 100)) return this.showLang('alarm.type.voltage.under');
      if (params.timeValue == 1 && params[`voltage${type}Value`] > (params[`voltage${type}Rated`] * params[`voltage${type}High`] / 100)) return this.showLang('alarm.type.voltage.over');
      return this.showLang('com.state.normal');
    },
    search: async function () {
      let ajaxData = {
        sid: this.item.stationId,//站点ID stationId
        paramId: this.item.id,//属性ID
        start: this.filter.start.format('yyyy-MM-dd HH:mm:00'),
        end: this.filter.end.format('yyyy-MM-dd HH:mm:59')
      }
      let res = await this.$axios.post(`//${this.domains.trans}/station/param/history`, ajaxData);
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return;
      }
      this.$set(this, "history", res.data);
      let legend = { type: 'scroll', right: 50, data: [this.showLang('com.lighting.leakage.val')] };
      let series = [
        { name: this.showLang('com.lighting.leakage.val'), type: 'line', yAxisIndex: 0, smooth: true, symbol: 'none', data: [] },
      ];
      let yAxis = [
        {
          type: 'value',
          // boundaryGap: true,
          boundaryGap: ['20%', '20%']
        },
      ];
      let grid = {
        bottom: 30,
        left: 60,
        right: 50,
        backgroundColor: '#ffffff',
        show: true
      };
      for (let row of res.data) {
        series[0].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.cl >= 0 ? row.content.cl : 0]);
      }
      this.$refs.lineChart1.loadData(series, legend, yAxis, grid);
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
.branch-table {
  height: 300px;
  overflow: auto;
}
.table {
  border-collapse: collapse;
  width: 100%;
}
.table-header {
  /* width: 1064px; */
  height: 47px;
  text-align: center;
  background: rgba(10, 26, 68, 0.8);
  box-shadow: inset 0px 0px 24px 0px #04559d;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #52fbfe;
  border: solid 1px black;
}
.table-header img {
  text-align: center;
  vertical-align: middle;
  margin-right: 3px;
}
.column-header {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  border: solid 1px black;
  text-align: center;
  height: 30px;
  /* width: 130px; */
  /* border-collapse: collapse; */
  background: #183992;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.column-header img {
  vertical-align: middle;
  margin-right: 10px;
}
.column-content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #050505;
  background: #2c9ef55e;
  border: solid 1px black;
  text-align: center;
  height: 35px;
  /* width: 130px; */
}
</style>