<template>
<div class="branch-table">
  <div :style="{height: `${index == 0 ? 0 : index * 230}px`}" class="branch-line"></div>
  <div class="branch-area">
    <table class="table">
      <tr>
        <td class="table-header tc" colspan="11">
          <div class="table-header-area">
            <div :style="{background: branch.content.tt ? '#01EBCD' : '#F0C633', width: '22px', height: '22px', lineHeight: '20px', marginRight: '10px', borderRadius: '4px', border: '1px solid #FFFFFF'}">{{branch.content.tt ? showLang('com.unit.main') : showLang('com.unit.part')}}</div>
            {{showLang('com.device.branch')}}: {{branch.name}} [{{new Date().long2str(branch.lastData.time)}}]
            <div style="width:100px;flex:auto;"></div>
            <div class="header-button" @click="showBranchData">{{showLang('history.data.look')}}</div>
          </div>
        </td>
        <!-- <td class="table-header-button" rowspan="5"><div class="view-branch-data" @click="showBranchData">查看历史数据一数据分析</div></td> -->
      </tr>
      <tr>
        <td class="column-header" style="width: 40px">#</td>
        <td class="column-header" style="width: 85px">{{showLang('com.device.branch.name')}}</td>
        <td class="column-header" style="width: 75px">{{showLang('com.state.lighting.status')}}</td>
        <td class="column-header" style="width: 85px"></td>
        <td class="column-header" style="width: 110px">{{showLang('com.lighting.I')}}</td>
        <td class="column-header" style="width: 100px">{{showLang('com.state')}}</td>
        <td class="column-header" style="width: 72px">{{showLang('com.lighting.cl')}}</td>
        <td class="column-header" style="width: 72px">{{showLang('com.lighting.theft.cable')}}</td>
        <td class="column-header" style="width: 82px">{{showLang('com.data.load.state')}}</td>
        <td class="column-header" style="width: 70px">{{showLang('com.device.cable.state')}}</td>
      </tr>
      <tr>
        <td class="column-content" rowspan="3">{{index+1}}</td>
        <td class="column-content" rowspan="3">{{branch.name}}</td>
        <td :class="['column-content', branch.lastData.tv == 1 ? 'status-green' : 'status-blue']" rowspan="3">{{branch.lastData.tv == 1 ? showLang('com.state.lighting.period') : showLang('alarm.type.power.close.period')}}</td>
        <td :class="['column-content']">{{showLang('cmd.project.A')}}</td>
        <td :class="['column-content', ch('a'), cl('a')]">{{cv('a')}}</td>
        <td :class="['column-content', ch('a'), cl('a')]">{{csn('a')}}</td>
        <td class="column-content" rowspan="3">{{cln}}</td>
        <td class="column-content" rowspan="3">{{sn}}</td>
        <td class="column-content">{{branch.content.count}} {{showLang('com.unit.calyx')}}</td>
        <td class="column-content">{{showLang('gis.set.cable.trend')}}</td>
      </tr>
      <tr>
        <td :class="['column-content']">{{showLang('cmd.project.B')}}</td>
        <td :class="['column-content', ch('b'), cl('b')]">{{cv('b')}}</td>
        <!-- <td :class="['column-content', branch.lastData.tv == 1 && branch.lastData.fa < branch.content.paf ? 'alarm-high' : '']">{{fv('a')}}</td>
        <td :class="['column-content', branch.lastData.tv == 1 && branch.lastData.fb < branch.content.pbf ? 'alarm-high' : '']">{{fv('b')}}</td>
        <td :class="['column-content', branch.lastData.tv == 1 && branch.lastData.fc < branch.content.pcf ? 'alarm-high' : '']">{{fv('c')}}</td> -->
        <td :class="['column-content', ch('b'), cl('b')]">{{csn('b')}}</td>
        <td class="column-content">{{tp}}</td>
        <td class="column-content">{{showLang('com.state.model')}}</td>
      </tr>
      <tr>
         <td :class="['column-content']">{{showLang('cmd.project.C')}}</td>
        <td :class="['column-content', ch('c'), cl('c')]">{{cv('c')}}</td>
        <!-- <td :class="['column-content', branch.lastData.tv == 1 && branch.lastData.pa > branch.content.par * branch.content.pah / 100 ? 'alarm-high' : '']">{{pv('a')}}</td>
        <td :class="['column-content', branch.lastData.tv == 1 && branch.lastData.pb > branch.content.pbr * branch.content.pbh / 100 ? 'alarm-high' : '']">{{pv('b')}}</td>
        <td :class="['column-content', branch.lastData.tv == 1 && branch.lastData.pc > branch.content.pcr * branch.content.pch / 100 ? 'alarm-high' : '']">{{pv('c')}}</td> -->
        <td :class="['column-content', ch('c'), cl('c')]">{{csn('c')}}</td>
        <td class="column-content">-</td>
        <td class="column-content">-</td>
      </tr>
    </table>
  </div>
  <ModalBranchData v-model="showDataModal" :item="branch" />
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalBranchData from './ModalBranchData'
export default {
  name: 'PowerBranchTable',
  components:{
    ModalBranchData,
  },
  props: {
    branch: {
      type: Object,
      default(){
        return {};
      }
    },
    index: {
      type:Number,
      default: 0
    }
  },
  data () {
    return {
      showLightModal: false,
      showDataModal: false,
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    cln: function(){ //漏电电流
      if(!this.branch.lastData)return '-';
      let cl = this.branch.lastData.cl;
      let ll = this.branch.content.ll;
      let lh = this.branch.content.lh;
      let lx = this.branch.content.lx;
      if(cl < 0) return '-';
      else if(cl > lx) return this.showLang('alarm.type.leakage.3');
      else if(cl > lh) return this.showLang('alarm.type.leakage.2');
      else if(cl > ll) return this.showLang('alarm.type.leakage.1');
      else return this.showLang('com.state.normal');
    },
    sn: function(){ //防盗状态
      if(!this.branch.lastData)return '-';
      let cs = this.branch.lastData.cs;
      let sh = this.branch.content.sh;
      if(cs < 0) return '-';
      else if(cs < sh) return this.showLang('com.state.normal');
      else return this.showLang('com.state.stolen');
    },
    tp: function(){ //总功率
      if(!this.branch.lastData)return '-';
      let pa = this.branch.lastData.pa;
      let pb = this.branch.lastData.pb;
      let pc = this.branch.lastData.pc;
      let t = 0;
      if(pa >= 0) t+=pa;
      if(pb >= 0) t+=pb;
      if(pc >= 0) t+=pc;
      return `${t.toFixed(2)} W`;
    },
    pv: function(){ //功率
      return function(flag){
        if(!this.branch.lastData)return '-';
        let v = this.branch.lastData[`p${flag}`];
        // console.log('pv', flag, v)
        let ph = this.branch.content[`p${flag}r`] * this.branch.content[`p${flag}h`] / 100;
        if(v >= 0)return `${v.toFixed(1)}(${ph})`;
        else if(v == -1)return this.showLang('com.state.not.measured');
        else if(v == -2)return this.showLang('com.state.not.configured');
        else return '--';
      }
    },
    fv: function(){ //功率因数
      return function(flag){
        if(!this.branch.lastData)return '-';
        let v = this.branch.lastData[`f${flag}`];
        let pf = this.branch.content[`p${flag}f`];
        if(v >= 0)return `${v.toFixed(3)}(${pf})`;
        else if(v == -1)return this.showLang('com.state.not.measured');
        else if(v == -2)return this.showLang('com.state.not.configured');
        else return '--';
      }
    },
    cv: function(){ //电流值
      return function(flag){
        if(!this.branch.lastData)return '-';
        let v = this.branch.lastData[`c${flag}`];
        if(v >= 0){
          let cl = (this.branch.content[`c${flag}r`] * this.branch.content[`c${flag}l`] / 100).toFixed(1);
          let ch = (this.branch.content[`c${flag}r`] * this.branch.content[`c${flag}h`] / 100).toFixed(1);
          return `${v.toFixed(1)}(${cl}-${ch})`;
        }
        else if(v == -1)return this.showLang('com.state.not.measured');
        else if(v == -2)return this.showLang('com.state.not.configured');// 未配置 显示过流
        else return '--';
      }
    },
    ch: function(){ //电流高值预警
      return function(flag){
        if(!this.branch.lastData)return '';
        let v = this.branch.lastData[`c${flag}`];
        if(v < 0) return '';
        let r = this.branch.content[`c${flag}r`];
        let h = this.branch.content[`c${flag}h`];
        if(this.branch.lastData.tv == 1 && v > r* h / 100)return 'alarm-hight';
        return '';
      }
    },
    cl: function(){ //电流高值预警
      return function(flag){
        if(!this.branch.lastData)return '';
        let v = this.branch.lastData[`c${flag}`];
        if(v < 0) return '';
        let r = this.branch.content[`c${flag}r`];
        let l = this.branch.content[`c${flag}l`];
        if(this.branch.lastData.tv == 1 && v < r* l / 100)return 'alarm-low';
        return '';
      }
    },
    csn: function(){ //电流状态名称
      return function(flag){
        if(!this.branch.lastData)return '-';
        let v = this.branch.lastData[`c${flag}`];
        if(v == -1)return this.showLang('com.state.not.measured');
        if(v == -2)return this.showLang('com.state.not.configured');
        let tv = this.branch.lastData.tv;
        if(tv != 1)return this.showLang('com.state.normal');
        let r = this.branch.content[`c${flag}r`];
        let h = this.branch.content[`c${flag}h`];
        let l = this.branch.content[`c${flag}l`];
        if(v > r * h / 100)return this.showLang('alarm.type.current.over');
        if(v < r * l / 100)return this.showLang('alarm.type.current.under');
        return this.showLang('com.state.normal');
      }
    },
  },
  mounted: function(){
    window.eventBus.$on('paramRealDataComing', params => {
      if(params.paramId != this.branch.id)return;
      this.$set(this.branch, 'lastData', params.data);
      this.$set(this.branch, 'extraData', params.extra);
    })
  },
  destroyed: function(){
    window.eventBus.$off('paramRealDataComing');
  },
  methods: {
    showLightData: function(){
      this.showLightModal = true;
    },
    showBranchData: function(){
      this.showDataModal = true;
    },
    panelClick: function(){
      return false;
    }
  }
}
</script>
<style scoped>
.branch-line{
  position: absolute;
  /* border: solid 1px red; */
  width: 100px;
  left: -100px;
  bottom: 100px;
  border-left: solid 3px #D6E0F0;
  border-bottom: solid 3px #D6E0F0;
  border-bottom-left-radius: 25px;
}
.branch-table{
  /* width: 700px; */
  /* height: 144px; */
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
  /* height: 178px; */
  width: 806px;
  background: #F0F4FA;
  border-radius: 12px;
  padding: 5px;
  position: relative;
}
.branch-area{
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 12px;
  /* padding: 10px; */
}
.contact-item-button{
  width: 80px;
  height: 44px;
  background-size: 100% 100%;
  margin: auto 50px;
}
.branch-item-road{
  width: 806px;
  height: 145px;
  margin-bottom: 10px;
}
.table{
  border-collapse: separate;
  /* border-radius: 12px; */
  border-spacing: 0;
}
.table tr:first-child td:first-child {
  border-top-left-radius: 12px;
}
.table tr:first-child td:last-child {
  border-top-right-radius: 12px;
}
.table tr:nth-child(3) td:first-child {
  border-bottom-left-radius: 12px;
}
.table tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}
.table-header{
  /* border-top-left-radius: 12px;
  border-top-right-radius: 12px; */
  background: linear-gradient(270deg, #3682FF, #4C9EFF);
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border-radius: 6px 6px 0px 0px;
  color: #FFFFFF;
  /* position: relative; */
  /* display: flex; */
}
.table-header-area{
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.header-button{
  /* position: absolute;
  top: 10px;
  right: 20px; */
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  line-height: 22px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  width: 89px;
  height: 25px;
  flex: none;
  cursor: pointer;
  margin-right: 20px;
}
.table-header img{
  text-align: center;
  vertical-align: middle;
  margin-right: 5px;
}
.column-header{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  opacity: 0.8;
  border: solid 1px #F0F4FA;
  text-align: center;
  height: 32px;
  color: #0D3A6A;
  font-weight: 400;
  background: #ffffff;
  overflow: hidden;
  /* text-overflow:ellipsis; */
  white-space: nowrap;
}
.column-header img{
  vertical-align: middle;
  margin-right: 10px;
}
.column-content{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* color: #52FBFE; */
  /* background: #2c9ef55e; */
  color: #0D3A6A;
  background: #ffffff;
  border: solid 1px #F0F4FA;
  text-align: center;
  height: 32px;
  /* width: 130px; */
}
.table-header-button{
  width: 30px;
  height: 150px;
  margin-top: 8px;
  /* border: 1px solid #56CCFD; */
  background: linear-gradient(180deg, #02B0F8, #074FBB);
  border-radius: 0px 6px 6px 0px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  /* flex: none;
  background: #0B1530;
  border-radius: 6px 0px 0px 6px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;  
  line-height: 24px;  
  font-size: 18px; */
}
.table-header-button div{
  width: 13px !important;
  /* border: 1px solid #56CCFD; */
  margin: 0 auto;
  line-height: 13px;
}
.view-branch-data{
  cursor: pointer;
}
</style>