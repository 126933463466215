<template>
  <div width="600px">
    <div slot="header">{{showLang('gis.lux.edit.dev')}}</div>
    <Form ref="form" :label-width="120">
      <FormItem :label="showLang('gis.lux.select.dev')">
        <Select :placeholder="showLang('save.select')" v-model="productId" @on-change="productChanged">
          <template v-for="(item, idx) in productList">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="radius" :label="showLang('gis.lux.radius')">
        <Input ref="lng" type="number" v-model="radius" @on-change="radiusChanged">
        <span slot="append">M</span>
        </Input>
      </FormItem>
      <FormItem prop="lng" :label="showLang('gis.where.lng')">
        <Input ref="lng" type="number" :value="lng" readonly>
        </Input>
      </FormItem>
      <FormItem prop="lat" :label="showLang('gis.where.lat')">
        <Input ref="lat" type="number" :value="lat" readonly>
        </Input>
      </FormItem>
    </Form>
    <div slot="footer" style="text-align: right;">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'setLuxRadius',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    productList: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      productId: '',
      radius: 0,
      lng: "",
      lat: "",
      current: {},
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.productId = '';
      }
    },
    // productId() {
    //   let product = this.productList.filter(p => p.id == this.productId);
    //   if(pr)
    //   product = product[0];
    //   this.radius = product.radius;
    //   this.lng = product.lng;
    //   this.lat = product.lat;
    //   this.$emit('posSaved', { id: this.productId, radius: this.radius });
    // },
    // productList() {
    //   let product = this.productList.filter(p => p.id == this.productId)[0];
    //   this.radius = product.radius;
    // },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
  },
  mounted: function () {
  },
  methods: {
    productChanged: function () {
      let products = this.productList.filter(p => p.id == this.productId);
      if (products.length == 0) return;
      // console.log('sel lux item', products)
      this.radius = products[0].path.radius;
      this.lng = products[0].lng;
      this.lat = products[0].lat;
      this.$set(this, 'current', products[0]);
      this.$emit('centerChanged', { lat: products[0].lat, lng: products[0].lng, zoom: 13 });
    },
    radiusChanged: function () {
      this.$emit('radiusChanged', { id: this.productId, radius: this.radius });
    },
    ok: async function () {
      if (this.productId == '') {
        this.$Message.warning(this.showLang('save.set.lux.dev'));
        return;
      }
      let ps = this.productList.filter(p => p.id == this.productId);
      if (ps.length == 0) {
        this.$Message.warning(this.showLang('save.set.lux.dev'));
        return;
      }
      let res = await this.$axios.post(`//${this.domains.trans}/station/gis/SetLuxRadius`, { id: ps[0].id, radius: this.radius });
      if (res.code == 0) {
        this.$Message.info(this.showLang('com.tips.set.success'));
        this.$emit('close');
      }

    },
    cancel() {
      this.$emit('close');
    },
  }
}
</script>
<style scoped>
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>