<template>
  <div class="main-table">
    <table class="table">
      <tr>
        <td colspan="8">
          <div class="table-header">
            <img :src="img.iconPowerMain" />
            {{mitem.DevName}} [{{mitem.AddTime}}]
          </div>
        </td>
      </tr>
      <tr>
        <td class="column-header tc">{{showLang('cmd.project')}}</td>
        <td class="column-header tc">{{showLang('cmd.project.A')}}</td>
        <td class="column-header tc">{{showLang('cmd.project.B')}}</td>
        <td class="column-header tc">{{showLang('cmd.project.C')}}</td>

        <td class="column-header tc">{{showLang('cmd.project')}}</td>
        <td class="column-header tc">{{showLang('cmd.project.A')}}</td>
        <td class="column-header tc">{{showLang('cmd.project.B')}}</td>
        <td class="column-header tc">{{showLang('cmd.project.C')}}</td>
      </tr>
      <tr>
        <td class="column-header pl20"><img :src="img.statusTitleV" />{{showLang('com.lighting.V')}}(V)</td>
        <td :class="['column-content', 'tc']">{{mitem.Ua}}</td>
        <td :class="['column-content', 'tc']">{{mitem.Ub}}</td>
        <td :class="['column-content', 'tc']">{{mitem.Uc}}</td>
        <td class="column-header"><img :src="img.statusTitleQ"
            style="margin: 0 3px" />{{showLang('com.data.power.factor')}}</td>
        <td :class="['column-content', 'tc']">{{mitem.PF_a}} </td>
        <td :class="['column-content', 'tc']">{{mitem.PF_b}} </td>
        <td :class="['column-content', 'tc']">{{mitem.PF_c}}</td>
      </tr>
      <tr style="background: #F6F9FE;">
        <td class="column-header pl20"><img :src="img.statusTitleC" />{{showLang('com.lighting.I')}}(A)</td>
        <td :class="['column-content', 'tc',]">{{mitem.Ia}}</td>
        <td :class="['column-content', 'tc',]">{{mitem.Ib}}</td>
        <td :class="['column-content', 'tc',]">{{mitem.Ic}}</td>
        <td class="column-header"><img :src="img.statusTitlePayload"
            style="margin: 0 3px" />{{showLang('com.lighting.ae')}}(kw)</td>
        <td :class="['column-content', 'tc',]">{{mitem.kWa}}</td>
        <td :class="['column-content', 'tc',]">{{mitem.kWb}}</td>
        <td :class="['column-content', 'tc',]">{{mitem.kWc}}</td>
      </tr>
      <tr style="background: #F6F9FE;">
        <td class="column-header pl20"><img :src="img.statusTitleV" />{{showLang('Output.voltage')}}</td>
        <td :class="['column-content', 'tc',]">{{mitem.Ua_out}}</td>
        <td :class="['column-content', 'tc',]">{{mitem.Ub_out}}</td>
        <td :class="['column-content', 'tc',]">{{mitem.Uc_out}}</td>
        <td class="column-header"><img :src="img.statusTitlePayload"
            style="margin: 0 3px" />{{showLang('com.lighting.re')}}(Kvar)</td>
        <td :class="['column-content', 'tc',]">{{mitem.kVAa}}</td>
        <td :class="['column-content', 'tc',]">{{mitem.kVAb}}</td>
        <td :class="['column-content', 'tc',]">{{mitem.kVAc}}</td>
      </tr>
    </table>
    <table class="table mt20">
      <tr>
        <td colspan="8">
          <div class="table-header">
            <img :src="img.iconPowerMain" />
            {{showLang('dev.contact')}}
          </div>
        </td>
      </tr>
      <tr>
        <td class="column-header tc">1{{showLang('op.road')}}</td>
        <td class="column-header tc">2{{showLang('op.road')}}</td>
        <td class="column-header tc">3{{showLang('op.road')}}</td>
        <td class="column-header tc">4{{showLang('op.road')}}</td>
        <td class="column-header tc">5{{showLang('op.road')}}</td>
        <td class="column-header tc">6{{showLang('op.road')}}</td>
        <td class="column-header tc">7{{showLang('op.road')}}</td>
        <td class="column-header tc">8{{showLang('op.road')}}</td>
      </tr>
      <tr>
        <td class="column-header tc">{{mitem.con1}}</td>
        <td class="column-header tc">{{mitem.con2}}</td>
        <td class="column-header tc">{{mitem.con3}}</td>
        <td class="column-header tc">{{mitem.con4}}</td>
        <td class="column-header tc">{{mitem.con5}}</td>
        <td class="column-header tc">{{mitem.con6}}</td>
        <td class="column-header tc">{{mitem.con7}}</td>
        <td class="column-header tc">{{mitem.con8}}</td>
      </tr>
      <tr style="background: #F6F9FE;">
        <td class="column-header tc" colspan="4">
          {{showLang('op.Energy.saving.bypass')}}
        </td>
        <td class="column-header tc" colspan="4">
          {{mitem.saving}}
        </td>
      </tr>
      <tr>
        <td class="column-header tc" colspan="2">
          {{showLang('op.Access.control')}}1
        </td>
        <td class="column-header tc" colspan="2">
          {{mitem.door1}}
        </td>
        <td class="column-header tc" colspan="2">
          {{showLang('op.Access.control')}}2
        </td>
        <td class="column-header tc" colspan="2">
          {{mitem.door2}}
        </td>
      </tr>

    </table>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
// import ModalMainData from './ModalMainData'
// import ModalVoltageData from './ModalVoltageData'
export default {
  name: 'PowerMainTable',
  components: {
    // ModalMainData,
    // ModalVoltageData,
  },
  props: {
    device: {
      type: Object,
      default() {
        return {};
      }
    },
    mitem: {
      type: Object,
      default() {
        return {};
      }
    },
    energy: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  data() {
    return {
      showDataModal: false,
      showVoltageModal: false,
      historyItem: {},
    }
  },
  watch: {
    mitem() {
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('cmd', ['singleCmdResult']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  created: function () {

  },
  mounted: function () {

  },
  destroyed: function () {

  },
  methods: {

  }
}
</script>
<style scoped>
.main-table {
  width: 1110px;
  /* height: 305px; */
  background: #f0f4fa;
  border-radius: 12px;
  padding: 10px;
}
.table-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 1090px;
  height: 50px;
  line-height: 47px;
  text-align: center;
  /* background: rgba(10, 26, 68, 0.8); */
  background: linear-gradient(270deg, #3682ff, #4c9eff);
  /* box-shadow: inset 0px 0px 24px 0px #04559D; */
  /* border-top: solid 1px #0a60b0; */
  /* border-bottom: solid 1px #0a60b0; */
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  position: relative;
  /* color: #52FBFE; */
  /* border: solid 1px black; */
}
.header-button {
  position: absolute;
  top: 10px;
  right: 10px;
  /* border: 1px solid #FFFFFF; */
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3880fc;
  width: 103px;
  height: 30px;
  background: #ffffff;
  border-radius: 6px;
  cursor: pointer;
}
.table {
  border-collapse: separate;
  /* border-radius: 12px; */
  border-spacing: 0;
}
.table tr:first-child td:first-child {
  border-top-left-radius: 12px;
}
.table tr:first-child td:last-child {
  border-top-right-radius: 12px;
}
.table tr:last-child td:first-child {
  border-bottom-left-radius: 12px;
}
.table tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}
.table-header img {
  vertical-align: middle;
  margin-right: 3px;
}
.column-header {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* color: #FFFFFF; */
  color: #0d3a6a;
  opacity: 0.8;
  border: solid 1px white;
  /* text-align: left; */
  /* padding-left: 20px; */
  height: 39px;
  width: 130px;
  /* border-collapse: collapse; */
  /* background: #183992; */
  background: #c8daef;
}
.column-header img {
  vertical-align: middle;
  margin-right: 10px;
}
.column-content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* color: #52FBFE; */
  color: #0d3a6a;
  background: #e8eff7;
  /* background: #2c9ef55e; */
  border: solid 1px white;
  /* text-align: left; */
  /* padding-left: 40px; */
  height: 32px;
  width: 130px;
  /* border: solid 1px red; */
}
</style>