<template>
  <div class="map-line-area">
    <div slot="header">{{showLang('gis.cable.set')}}</div>
    <Form ref="form" :label-width="80">
      <FormItem :label="showLang('gis.cable.select')">
        <div style="display: flex">
          <Select :placeholder="showLang('save.select')" v-model="productId" @on-change="lineChanged">
            <template v-for="(item) in deviceList">
              <Option :value="item.id" :key="item.id">{{item.name}}</Option>
            </template>
          </Select>
          <!-- <Button type="success" style="margin-left: 10px" @click="addPowerLine">新增</Button>
        <Button type="error" style="margin-left: 10px" @click="deletePowerLine">删除</Button> -->
        </div>
      </FormItem>
      <FormItem prop="lng" :label="showLang('gis.pathStart')">
        <Input ref="lng" type="text" :value="pathStart" readonly>
        </Input>
      </FormItem>
      <FormItem prop="lat" :label="showLang('gis.pathEnd')">
        <Input ref="lat" type="text" :value="pathEnd" :placeholder="showLang('gis.click.obtain.position')" readonly>
        </Input>
      </FormItem>
    </Form>
    <div slot="footer" style="text-align: right;">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'setLuxRadius',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    deviceList: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      productId: '',
      route: [],
      oneRoute: {},
      product: {
        name: "",
        deviceId: "",
        remarks: "",
      },
      path: [],
    }
  },
  watch: {
    productId() {
      // let el = this.deviceList.filter(p => p.id == this.productId)[0];
      // let data = {
      //   lng: el.lng,
      //   lat: el.lat
      // }
      // if (el.extraData.gis.route.length == 0) {
      //   el.extraData.gis.route.push(data);
      // }
      // this.oneRoute = el;
      // this.$emit('posSaved', { id: this.productId, length: el.extraData.gis.route.length });
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    pathStart: function () {
      if (this.path.length == 0) return '[]';
      return `[${this.path[0].lat}, ${this.path[0].lng}]`
    },
    pathEnd: function () {
      if (this.path.length == 0) return '[]';
      return `[${this.path[this.path.length - 1].lat}, ${this.path[this.path.length - 1].lng}]`
    },
  },
  mounted: function () {
  },
  methods: {
    addPowerLine: function () {
    },
    deletePowerLine: function () {
      let ls = this.deviceList.filter(p => p.id == this.productId);
      if (ls.length == 0) {
        this.$Message.warning(this.showLang('save.del.cable'));
        return false;
      }
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content:this.showLang('com.tips.ok.del.name',ls[0].name),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteDevice`, { list: [ls[0].id] }).then(res => {
            if (res.code == 0) {
              for (let i = 0; i < this.deviceList.length; i++) {
                if (this.deviceList[i].id == this.productId) {
                  this.deviceList.splice(i, 1);
                  this.$emit('lineDelete', { id: this.productId });
                  return;
                }
              }
            }
          });
        }
      });
    },
    lineChanged: function () {
      let el = this.deviceList.filter(p => p.id == this.productId)[0];
      // let data = {
      //   lng: el.lng,
      //   lat: el.lat
      // }
      // console.log('line path', el, data);
      if (el.path.list.length > 0) {
        this.$emit('centerChanged', { lat: el.path.list[0].lat, lng: el.path.list[0].lng, zoom: 18 });
      }
      this.$set(this, 'path', el.path.list)
      this.$emit('startEdit', { id: this.productId });
      // if (el.extraData.gis.route.length == 0) {
      //   el.extraData.gis.route.push(data);
      // }
      // this.oneRoute = el;
      // this.$emit('posSaved', { id: this.productId, length: el.extraData.gis.route.length });
    },
    ok: async function () {
      if (this.productId == '') {
        this.$Message.warning(this.showLang('save.cable'));
        return;
      }
      // let ps = this.deviceList.filter(p => p.id == this.productId);
      // if (ps.length == 0) {
      //   this.$Message.warning('请选择电缆设备');
      //   return;
      // }
      // let data = {
      //   "sid": ps[0].stationId,
      //   "paramId": ps[0].id,
      //   "key": "gis",
      //   "value": { route: ps[0].extraData.gis.route },
      // };
      // let res = await this.$axios.post(`//${this.domains.trans}/station/param/extra`, data);
      // if (res.code !== 0) {
      //   this.$Message.error(res.data);
      //   return;
      // }
      this.$emit('lineSave', { id: this.productId });
    },
    cancel() {
      this.$emit('close');
    },
  }
}
</script>
<style scoped>
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
.map-line-area {
  width: 400px;
}
</style>