<template>
  <Modal v-model="showModal" :mask-closable="false" draggable width="1000px">
    <div slot="header">{{showLang('com.device.box')}} {{device ? device.name : ''}} {{showLang('com.device.branch')}} {{item.name}}
      {{showLang('history.data')}}</div>
    <Form ref="form" :label-width="0">
      <FormItem :label="showLang('com.date.time')" :label-width="80">
        <div style="display: flex">
          <DatePicker type="datetime" v-model="filter.start" format="yyyy-MM-dd HH:mm" style="width: 155px; margin-right: 10px"></DatePicker>
          {{showLang('com.date.to')}}
          <DatePicker type="datetime" v-model="filter.end" format="yyyy-MM-dd HH:mm" style="width: 155px; margin-left: 10px"></DatePicker>
          <Button type="primary" style="margin-left: 10px" @click="search">{{showLang('com.op.query')}}</Button> <span
            style="margin-left: 20px">{{showLang('com.data.sum.record',history.length)}}</span>
        </div>
      </FormItem>
      <FormItem>
        <LineChart ref="lineChart1" :config="config" :id="`pb-${device.code}-branch-${item.id}`" style="width: 968px;height: 300px" />
      </FormItem>
      <FormItem>
        <div class="branch-table">
          <table class="table">
            <tr>
              <td class="column-header" style="width: 130px">{{showLang('com.date.occurrence')}}</td>
              <!-- <td class="column-header" style="width: 72px">支路名称</td> -->
              <td class="column-header" style="width: 75px">{{showLang('com.state.lighting.status')}}</td>
              <td class="column-header" style="width: 85px"></td>
              <td class="column-header" style="width: 110px">{{showLang('com.lighting.I')}}</td>
              <td class="column-header" style="width: 100px">{{showLang('com.state')}}</td>
              <td class="column-header" style="width: 72px">{{showLang('com.lighting.cl')}}</td>
              <td class="column-header" style="width: 72px">{{showLang('com.lighting.theft.cable')}}</td>
              <td class="column-header" style="width: 82px">{{showLang('com.data.load.state')}}</td>
              <td class="column-header" style="width: 70px">{{showLang('com.device.cable.state')}}</td>
            </tr>
            <template v-for="(branch, index) in history">
              <tr :key="`data-${index}-1`">
                <td class="column-content" rowspan="3">{{new Date(branch.fireTime).format('yyyy-MM-dd HH:mm:ss')}}</td>
                <td class="column-content" rowspan="3">
                  {{branch.lastData && branch.lastData.tv == 1 ? showLang('com.state.lighting.period') : showLang('alarm.type.power.close.period')}}</td>
                <td :class="['column-content']">{{showLang('cmd.project.A')}}</td>
                <td class="column-content">{{img.val(branch.content.ca, 2)}} A</td>
                <td class="column-content">{{currentStatus(branch, 'A')}}</td>
                <td class="column-content" rowspan="3">{{cln(branch)}}</td>
                <td class="column-content" rowspan="3">{{sn(branch)}}</td>
                <td class="column-content">{{showLang('com.state.type')}}</td>
                <td class="column-content">{{showLang('gis.set.cable.trend')}}</td>
              </tr>
              <tr :key="`data-${index}-2`">
                <td :class="['column-content']">{{showLang('cmd.project.B')}}</td>
                <td class="column-content">{{img.val(branch.content.cb, 2)}} A</td>
                <td class="column-content">{{currentStatus(branch, 'B')}}</td>
                <td class="column-content">{{tp(branch)}}</td>
                <td class="column-content">{{showLang('com.state.model')}}</td>
              </tr>
              <tr :key="`data-${index}-3`">
                <td :class="['column-content']">{{showLang('cmd.project.C')}}</td>
                <td class="column-content">{{img.val(branch.content.cc, 2)}} A</td>
                <td class="column-content">{{currentStatus(branch, 'C')}}</td>
                <td class="column-content">{{showLang('com.data')}}</td>
                <td class="column-content">{{showLang('com.unit.length')}}</td>
              </tr>
            </template>
          </table>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex'
import LineChart from '@/components/charts/LineChart'
export default {
  name: 'ModalBranchData',
  components: {
    LineChart,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    device: {
      type: Object,
      default() { return {}; }
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      filter: {
        start: '',
        end: '',
      },
      history: [],
      loading: false,
      configureData: {},
      config: {
        title: ""
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        let now1 = new Date();
        now1.setDate(now1.getDate() - 7);
        this.filter.start = now1;
        this.filter.end = new Date();
        this.search();
      }
      this.config.title = this.showLang('com.device.branch') + "[" + this.item.name + "]" + this.showLang('history.data');
    },
    showModal(val) {
      this.config.title = this.showLang('com.device.branch') + "[" + this.item.name + "]" + this.showLang('history.data');
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
    this.configureData = this.item.content;
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
  },
  methods: {
    pv: function (v) { //功率
      if (v >= 0) return `${v} W`;
      else if (v == -1) return this.showLang("com.state.not.measured");
      else if (v == -2) return this.showLang("com.state.not.configured");
      else return '--';
    },
    tp: function (params) { //总功率
      if (!params.content) return '-';
      let pa = params.content.pa;
      let pb = params.content.pb;
      let pc = params.content.pc;
      let t = 0;
      if (pa >= 0) t += pa;
      if (pb >= 0) t += pb;
      if (pc >= 0) t += pc;
      return `${t} W`;
    },
    cln: function (params) { //漏电电流
      if (!params.content) return '-';
      let cl = params.content.cl;
      let ll = this.configureData.ll;
      let lh = this.configureData.lh;
      if (cl < 0) return '-';
      else if (cl < ll) return this.showLang("com.state.normal");
      else if (cl < lh) return this.showLang("com.lighting.minor.leakage");
      else return this.showLang("com.lighting.severe.leakage");
    },
    sn: function (params) { //防盗状态
      if (!params.content) return '-';
      let cs = params.content.cs;
      let sh = this.configureData.sh;
      if (cs < 0) return '-';
      else if (cs < sh) return this.showLang("com.state.normal");
      else return this.showLang("com.state.stolen");
    },
    currentStatus: function (params, type) {//电流状态名称
      if (!params.content) return '-';
      let tv = params.content.tv;
      if (tv != 1) return this.showLang("com.state.normal");
      let v = params.content[`c${type}`];
      let r = this.configureData[`c${type}r`];
      let h = this.configureData[`c${type}h`];
      let l = this.configureData[`c${type}l`];
      if (v > r * h / 100) return this.showLang("alarm.type.current.over");
      if (v < r * l / 100) return this.showLang("alarm.type.current.under");
      return this.showLang("com.state.normal");
    },
    search: async function () {
      let ajaxData = {
        sid: this.item.stationId,//站点ID
        paramId: this.item.id,//属性ID
        start: this.filter.start.format('yyyy-MM-dd HH:mm:00'),
        end: this.filter.end.format('yyyy-MM-dd HH:mm:59')
      }
      let res = await this.$axios.post(`//${this.domains.trans}/station/param/history`, ajaxData);
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return;
      }
      this.$set(this, "history", res.data);
      let legend = { type: 'scroll', right: 50, data: [this.showLang("cmd.project.A.I"), this.showLang("cmd.project.B.I"), this.showLang("cmd.project.C.I")] };//, 'A相功率', 'B相功率', 'C相功率'
      let series = [
        { name: this.showLang('cmd.project.A.I'), type: 'line', yAxisIndex: 0, smooth: true, symbol: 'none', data: [] },
        { name: this.showLang('cmd.project.B.I'), type: 'line', yAxisIndex: 0, smooth: true, symbol: 'none', data: [] },
        { name: this.showLang('cmd.project.C.I'), type: 'line', yAxisIndex: 0, smooth: true, symbol: 'none', data: [] },
        // { name: 'A相功率', type: 'line', yAxisIndex: 1, smooth: true, symbol: 'none', data: [] },
        // { name: 'B相功率', type: 'line', yAxisIndex: 1, smooth: true, symbol: 'none', data: [] },
        // { name: 'C相功率', type: 'line', yAxisIndex: 1, smooth: true, symbol: 'none', data: [] },
      ];
      let yAxis = [
        {
          type: 'value',
          name: `${this.showLang('com.lighting.I')}(A)`,
          // boundaryGap: true,
          boundaryGap: ['20%', '20%']
        },
        // {
        //   type: 'value',
        //   name: '功率(W)',
        //   boundaryGap: ['20%', '20%'],
        // }
      ];
      let grid = {
        bottom: 30,
        left: 60,
        right: 50,
        backgroundColor: '#ffffff',
        show: true
      };
      for (let row of res.data) {
        series[0].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.ca >= 0 ? row.content.ca : 0]);
        series[1].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.cb >= 0 ? row.content.cb : 0]);
        series[2].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.cc >= 0 ? row.content.cc : 0]);
        // series[3].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.pa>=0?row.content.pa:0]);
        // series[4].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.pb>=0?row.content.pb:0]);
        // series[5].data.push([new Date(row.fireTime).format('yyyy-MM-dd HH:mm:ss'), row.content.pc>=0?row.content.pc:0]);
      }
      this.$refs.lineChart1.loadData(series, legend, yAxis, grid);
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
.branch-table {
  height: 300px;
  overflow: auto;
}
.table {
  border-collapse: collapse;
  width: 100%;
}
.table-header {
  /* width: 1064px; */
  height: 47px;
  text-align: center;
  background: rgba(10, 26, 68, 0.8);
  box-shadow: inset 0px 0px 24px 0px #04559d;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #52fbfe;
  border: solid 1px black;
}
.table-header img {
  text-align: center;
  vertical-align: middle;
  margin-right: 3px;
}
.column-header {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  border: solid 1px black;
  text-align: center;
  height: 30px;
  /* width: 130px; */
  /* border-collapse: collapse; */
  background: #183992;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.column-header img {
  vertical-align: middle;
  margin-right: 10px;
}
.column-content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #050505;
  background: #2c9ef55e;
  border: solid 1px black;
  text-align: center;
  height: 35px;
  /* width: 130px; */
}
</style>