<template>
  <div class="box-header2">
    <div class="header-info">
      <div class="header-info-item"><img :src="img.light.road.single.header.unit" />{{showLang('com.site.info.manage')}}: {{organOwner.name}}</div>
      <div class="header-info-item"><img :src="img.light.road.single.header.personal" />{{showLang('com.site.info.liable')}}: {{organOwner.linkman}}</div>
      <div class="header-info-item"><img :src="img.light.road.single.header.phone" />{{showLang('com.site.phone')}}: {{organOwner.mobile}}</div>
      <div class="header-info-item"><img :src="img.light.road.single.header.unit" />{{showLang('com.site.info.servic')}}: {{organMaintenance.name}}</div>
      <div class="header-info-item"><img :src="img.light.road.single.header.personal" />{{showLang('com.site.info.liable')}}: {{organMaintenance.linkman}}</div>
      <div class="header-info-item"><img :src="img.light.road.single.header.phone" />{{showLang('com.site.phone')}}: {{organMaintenance.mobile}}</div>
    </div>
    <div class="header-simcard">
      <template v-for="(t, idx) in tops">
        <div class="simcard-item" :key="t.id" v-if="idx==0"><img :src="img.light.road.single.header.card" />{{showLang('com.site.deviceID')}}: {{t.code}}, {{showLang('com.site.block')}}{{idx+1}}：ICCID: {{t.simCard || '-'}}, IMEI: {{t.imei || '-'}}, RSSI: {{t.rssi || '-'}}, {{showLang('com.site.hw')}}：{{t.hardwareVersion || '-'}}, {{showLang('com.site.sw')}}：{{t.softwareVersion || '-'}}</div>
      </template>
      <template v-if="tops.length>1">
        <Poptip v-model="visible">
          <a class="header-info-more">{{showLang('com.op.more')}}</a>
          <div slot="content" class="header-info-moreBox">
            <div class="simcard-item" v-for="(t, idx) in tops" :key="t.id">{{showLang('com.site.deviceID')}}: {{t.code}}, {{showLang('com.site.block')}}{{idx+1}}：ICCID: {{t.simCard || '-'}}, IMEI: {{t.imei || '-'}}, RSSI: {{t.rssi || '-'}}, {{showLang('com.site.hw')}}：{{t.hardwareVersion || '-'}}, {{showLang('com.site.sw')}}：{{t.softwareVersion || '-'}}</div>
          </div>
        </Poptip>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'StationBaseIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      organMaintenance: {},
      organOwner: {},
      visible: false,
      tops: [],
    }
  },
  computed: {
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState('common', ['weeks', 'productCodes', 'deviceTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    currentStation: function () {
      if (this.selectedNode.type == this.productCodes.station) {
        return this.selectedNode;
      }
      return null;
    },
  },
  watch: {
    selectedNode() {
      this.initData();
      this.queryWorkData();
    }
  },
  mounted: function () {
    this.initData();
    this.queryWorkData();
  },
  destroyed: function () {
  },
  methods: {
    queryWorkData: function(){
      if (!this.currentStation) {
        this.$set(this, 'organMaintenance', {});
        this.$set(this, 'organOwner', {});
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/Maintance/QueryWorkInfo`, { stationId: this.currentStation.id }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'organMaintenance', res.data.maintenance);
          this.$set(this, 'organOwner', res.data.manage);
        }
      });
    },
    initData: function () {
      if (!this.currentStation) {
        this.$set(this, 'tops', []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryTops`, { stationId: this.currentStation.id }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'tops', res.data);
          this.querySimcard(0)
        }
      });
    },
    querySimcard(num){
      if(this.tops.length==num){
        return false
      }
      let filter={
        id: this.tops[num].code,
        iccid: '',
        imei: '',
        index: 1,
        size: 2000,
      }
      this.$axios.post(`//${this.domains.trans}/station/search/QuerySimcard`, filter).then(res => {
        if(res.code == 0){
          let sim=res.data.list.filter(p => p.id == filter.id);
          let sim2=this.tops.filter(p => p.code == filter.id);
          if(sim2.length>0){
            sim[0].simCard=sim2[0].iccid;
            sim[0].imei=sim2[0].imei;
            sim[0].hardwareVersion=sim2[0].hardware;
            sim[0].softwareVersion=sim2[0].software;
          }
        }
        num++;
        this.querySimcard(num)
      });
    },
  }
}
</script>
<style scoped>
/* 站点信息 */
.box-header2 {
  flex: none;
  /* height: 172px; */
  background: #fff;
  margin-top: 16px;
  position: relative;
  padding: 0 30px;
}
.box-header2-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
  border-bottom: 1px solid #dee5ed;
  padding-bottom: 15px;
}
.header-info-region {
  float: right;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  margin-right: 35px;
}
.header-info {
  color: #324252;
  display: flex;
  justify-content: space-evenly;
  padding: 0 0 5px;
}
.header-info-item {
  flex: auto;
  text-align: left;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.header-info-item img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
  margin-top: -3px;
}
.header-info-region img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
  margin-top: -3px;
}
.header-simcard {
  height: 20px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff99;
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
}
.header-info-moreBox .simcard-item {
  margin-top: 10px;
}
.simcard-item {
  border-radius: 6px;
  line-height: 12px;
  padding: 2px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.simcard-item img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-left: -2px;
  margin-top: -3px;
}
</style>