<template>
  <table class="history-item">
    <tr>
      <td colspan="3" class="main-header"><img :src="img.mutiSearchFireTime"
          style="vertical-align: middle;margin: 0 5px;" />{{showLang('com.date.occurrence')}}：{{new Date(source.fireTime).format('yyyy-MM-dd HH:mm:ss')}}</td>
    </tr>
    <tr class="sub-header">
      <td>{{showLang('dev.main')}}</td>
      <td>{{showLang('cmd.project.A')}}</td>
      <td>{{showLang('cmd.project.B')}}</td>
      <td>{{showLang('cmd.project.C')}}</td>
      <td>{{showLang('history.data.monitoring.items')}}</td>
      <td>{{showLang('history.data.monitoring.val')}}</td>
      <td>{{showLang('history.data.monitoring.items')}}</td>
      <td>{{showLang('history.data.monitoring.val')}}</td>
      <td>{{showLang('history.data.monitoring.items')}}</td>
      <td colspan="2">{{showLang('history.data.monitoring.val')}}</td>
    </tr>
    <tr>
      <td class="detail-header">{{showLang('com.lighting.V')}}</td>
      <td>{{img.val(source.main.content.ua,2)}}V</td>
      <td>{{img.val(source.main.content.ub,2)}}V</td>
      <td>{{img.val(source.main.content.uc,2)}}V</td>
      <td class="detail-header">{{showItem(1).name}}</td>
      <td>{{showItem(1).value}}</td>
      <td class="detail-header">{{showItem(10).name}}</td>
      <td>{{showItem(10).value}}</td>
      <td class="detail-header">{{showItem(19).name}}</td>
      <td colspan="2">{{showItem(19).value}}</td>
    </tr>
    <tr>
      <td class="detail-header">{{showLang('com.lighting.V.status')}}</td>
      <td class="active">{{voltageStatus(source.main, 'a')}}</td>
      <td class="active">{{voltageStatus(source.main, 'b')}}</td>
      <td class="active">{{voltageStatus(source.main, 'c')}}</td>
      <td class="detail-header">{{showItem(2).name}}</td>
      <td class="active">{{showItem(2).value}}</td>
      <td class="detail-header">{{showItem(11).name}}</td>
      <td class="active">{{showItem(11).value}}</td>
      <td class="detail-header">{{showItem(20).name}}</td>
      <td class="active" colspan="2">{{showItem(20).value}}</td>
    </tr>
    <tr>
      <td class="detail-header">{{showLang('com.lighting.total.I')}}</td>
      <td>{{img.val(source.main.content.ca,2)}}A</td>
      <td>{{img.val(source.main.content.cb,2)}}A</td>
      <td>{{img.val(source.main.content.cc,2)}}A</td>
      <td class="detail-header">{{showItem(3).name}}</td>
      <td>{{showItem(3).value}}</td>
      <td class="detail-header">{{showItem(12).name}}</td>
      <td>{{showItem(12).value}}</td>
      <td class="detail-header">{{showItem(21).name}}</td>
      <td colspan="2">{{showItem(21).value}}</td>
    </tr>
    <tr>
      <td class="detail-header">{{showLang('com.data.power.factor')}}</td>
      <td class="active">{{img.val(source.main.content.fa,2)}}</td>
      <td class="active">{{img.val(source.main.content.fb,2)}}</td>
      <td class="active">{{img.val(source.main.content.fc,2)}}</td>
      <td class="detail-header">{{showItem(4).name}}</td>
      <td class="active">{{showItem(4).value}}</td>
      <td class="detail-header">{{showItem(13).name}}</td>
      <td class="active">{{showItem(13).value}}</td>
      <td class="detail-header">{{showItem(22).name}}</td>
      <td colspan="2" class="active">{{showItem(22).value}}</td>
    </tr>
    <tr>
      <td class="detail-header">{{showLang('com.data.active.power')}}</td>
      <td>{{img.val(source.main.content.pa,2)}}W</td>
      <td>{{img.val(source.main.content.pb,2)}}W</td>
      <td>{{img.val(source.main.content.pc,2)}}W</td>
      <td class="detail-header">{{showItem(5).name}}</td>
      <td>{{showItem(5).value}}</td>
      <td class="detail-header">{{showItem(14).name}}</td>
      <td>{{showItem(14).value}}</td>
      <td class="detail-header">{{showItem(23).name}}</td>
      <td colspan="2">{{showItem(23).value}}</td>
    </tr>
    <tr>
      <td class="detail-header">{{showLang('com.lighting.q.total')}}</td>
      <td class="active" colspan="3">{{valueData(source.main.content.e)}}KW</td>
      <td class="detail-header">{{showItem(6).name}}</td>
      <td class="active">{{showItem(6).value}}</td>
      <td class="detail-header">{{showItem(15).name}}</td>
      <td class="active">{{showItem(15).value}}</td>
      <td class="detail-header">{{showItem(24).name}}</td>
      <td colspan="2" class="active">{{showItem(24).value}}</td>
    </tr>
    <tr>
      <td class="detail-header"> {{showLang('com.lighting.T')}}</td>
      <td colspan="3">{{img.val(source.main.content.t,2)}}℃</td>
      <td class="detail-header">{{showItem(7).name}}</td>
      <td>{{showItem(7).value}}</td>
      <td class="detail-header">{{showItem(16).name}}</td>
      <td>{{showItem(16).value}}</td>
      <td class="detail-header">{{showItem(25).name}}</td>
      <td colspan="2">{{showItem(25).value}}</td>
    </tr>
    <tr>
      <td class="detail-header"> {{showLang('com.import.cn.lng')}}</td>
      <td class="active" colspan="3">{{lng}}</td>
      <td class="detail-header">{{showItem(8).name}}</td>
      <td class="active">{{showItem(8).value}}</td>
      <td class="detail-header">{{showItem(17).name}}</td>
      <td class="active">{{showItem(17).value}}</td>
      <td class="detail-header">{{showItem(26).name}}</td>
      <td colspan="2" class="active">{{showItem(26).value}}</td>
    </tr>
    <tr>
      <td class="detail-header"> {{showLang('com.import.cn.lat')}}</td>
      <td colspan="3">{{lat}}</td>
      <td class="detail-header">{{showItem(9).name}}</td>
      <td>{{showItem(9).value}}</td>
      <td class="detail-header">{{showItem(18).name}}</td>
      <td>{{showItem(18).value}}</td>
      <td class="detail-header">{{showItem(27).name}}</td>
      <td colspan="2">{{showItem(27).value}}</td>
    </tr>
    <tr>
      <td colspan="11" style="height: 5px"></td>
    </tr>
    <tr class="sub-header branch-title">
      <td>{{showLang('com.device.branch')}}</td>
      <td>{{showLang('com.stat.light.rate')}}</td>
      <td>{{showLang('cmd.project.A.I')}}</td>
      <td>{{showLang('com.lighting.I.state')}}</td>
      <td>{{showLang('cmd.project.B.I')}}</td>
      <td>{{showLang('com.lighting.I.state')}}</td>
      <td>{{showLang('cmd.project.C.I')}}</td>
      <td>{{showLang('com.lighting.I.state')}}</td>
      <td>{{showLang('com.lighting.cl')}}</td>
      <td>{{showLang('com.lighting.cl.state')}}</td>
      <td>{{showLang('com.lighting.theft.cable')}}</td>
    </tr>
    <tr v-for="(branch, idx) in source.branchs" :key="idx">
      <td class="detail-header">{{branch.name}}</td>
      <td :class="idx % 2 == 0 ? '' : 'active'">{{branch.content.tv == 1 ? showLang('com.state.lighting.period') : (branch.content.tv == 0 ? showLang('com.state.no.lighting.period') : '--')}}</td>
      <td :class="idx % 2 == 0 ? '' : 'active'">{{valueData(branch.content.ca)}} A ({{(branch.cfg.car * branch.cfg.cal / 100).toFixed(2)}} -
        {{(branch.cfg.car * branch.cfg.cah / 100).toFixed(2)}})</td>
      <td :class="idx % 2 == 0 ? '' : 'active'">{{currentStatus(branch, 'a')}}</td>
      <td :class="idx % 2 == 0 ? '' : 'active'">{{valueData(branch.content.cb)}} A ({{(branch.cfg.cbr * branch.cfg.cbl / 100).toFixed(2)}} -
        {{(branch.cfg.cbr * branch.cfg.cbh / 100).toFixed(2)}})</td>
      <td :class="idx % 2 == 0 ? '' : 'active'">{{currentStatus(branch, 'b')}}</td>
      <td :class="idx % 2 == 0 ? '' : 'active'">{{valueData(branch.content.cc)}} A ({{(branch.cfg.ccr * branch.cfg.ccl / 100).toFixed(2)}} -
        {{(branch.cfg.ccr * branch.cfg.cch / 100).toFixed(2)}})</td>
      <td :class="idx % 2 == 0 ? '' : 'active'">{{currentStatus(branch, 'c')}}</td>
      <td :class="idx % 2 == 0 ? '' : 'active'">{{valueData(branch.content.cl)}} mA</td>
      <td :class="idx % 2 == 0 ? '' : 'active'">{{leakageStatus(branch)}}</td>
      <td :class="idx % 2 == 0 ? '' : 'active'">{{branch.content.cs > branch.cfg.sc ? showLang('com.state.stolen') : showLang('com.state.normal')}}</td>
    </tr>
  </table>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: 'PowerHistoryItem',
  components: {
  },
  props: {
    index: { // 每一行的索引
      type: Number
    },
    source: { // 每一行的内容
      type: Object,
      default() {
        return {}
      }
    },
    title: {
      type: String
    },
    lat: {
      type: Number
    },
    lng: {
      type: Number
    },
  },
  data() {
    return {
      contactTexts: {
        0: '停止',
        1: '手动',
        2: '遥控',
        3: '时控',
      },
      types: { 0: '关闭', 1: '开启', 2: '无效' },
      showModal: false,
      loading: false,
      main: {
        va: 0,
        vb: 0,
        vc: 0,
        vaState: '',
        vbState: '',
        vcState: '',
        iav: 0,
        iaf: 0,
        iap: 0,
        ibv: 0,
        ibf: 0,
        ibp: 0,
        icv: 0,
        icf: 0,
        icp: 0,
        energy: 0,
        temp: 0,
        lat: 0,
        lng: 0,
      },
      doors: [],//门
      branchs: [],//分支
      locks: [],//锁
      switchs: [],//开关
      outputs: [],//输出
      contacts: []//联络
      ///   2、转换开关；
      ///   3、控制输出开关；
      ///   4、接触器；
      ///   5、支路配电；
      ///   6、柜门；
      ///   7、门锁；
      ///   8、烟雾；
      ///   9、水浸；
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    showItem: function () {
      return function (idx) {
        let len = this.source.doors.length;
        if (idx <= len) {
          let source = this.source.doors[idx - 1];
          return { name: source.name, value: this.openState(source.content.sv == source.content.av) };
        }
        idx -= len;
        len = this.source.locks.length;
        if (idx <= len) {
          let source = this.source.locks[idx - 1];
          return { name: source.name, value: this.openState(source.content.sv == source.content.av) };
        }
        idx -= len;
        len = this.source.switchs.length;
        if (idx <= len) {
          let source = this.source.switchs[idx - 1];
          return { name: source.name, value: this.switchStatus(source) };
        }
        idx -= len;
        len = this.source.contacts.length;
        if (idx <= len) {
          let source = this.source.contacts[idx - 1];
          return { name: source.name, value: this.contactTexts[source.content.sv] };
        }
        idx -= len;
        len = this.source.outputs.length;
        if (idx <= len) {
          let source = this.source.outputs[idx - 1];
          return { name: source.name, value: this.openState(source.content.ov == 0) };
        }
        idx -= len;
        len = this.source.smokes.length;
        if (idx <= len) {
          let source = this.source.smokes[idx - 1];
          return { name: source.name, value: this.alarmState(source.content.sv == source.content.av) };
        }
        idx -= len;
        len = this.source.waters.length;
        if (idx <= len) {
          let source = this.source.waters[idx - 1];
          return { name: source.name, value: this.waterState(source) };
        } else {
          return { name: '--', value: '--' }
        }
      }
    },
  },
  mounted: function () {
    this.contactTexts = {
      0:this.showLang('com.op.cease'),
      1:this.showLang('com.op.hand'),
      2:this.showLang('com.op.remote'),
      3:this.showLang('com.op.time.control'),
    }
  },
  destroyed: function () {
  },
  methods: {
    valueData: function (v) {
      if (v == -2) return this.showLang('com.not.set');
      else if (v == -1) return this.showLang('com.state.not.measured');
      else if (v >= 0) return v;
      else return '-';
    },
    openState: function (val) {
      return !val ? this.showLang('com.state.normal') : this.showLang('com.state.break')
    },
    alarmState: function (val) {
      return !val ? this.showLang('com.state.normal') : this.showLang('com.right.nav.alarm')
    },
    leakageStatus: function (params) {
      if (params.content.cl > (params.cfg.lh)) return this.showLang('com.lighting.severe.leakage');
      if (params.content.cl > (params.cfg.ll)) return this.showLang('com.lighting.minor.leakage');
      return this.showLang('com.state.normal');
    },
    currentStatus: function (params, type) {
      if (params.content.tv != 1) return this.showLang('com.state.normal');
      if (params.content[`c${type}`] < (params.cfg[`c${type}r`] * params.cfg[`c${type}l`] / 100)) return this.showLang('alarm.type.current.under');
      if (params.content[`c${type}`] > (params.cfg[`c${type}r`] * params.cfg[`c${type}h`] / 100)) return this.showLang('alarm.type.current.over');
      return this.showLang('com.state.normal');
    },
    voltageStatus: function (params, type) {
      if (params.content.tv != 1) return this.showLang('com.state.normal');
      if (params.content[`u${type}`] < (params.cfg[`u${type}r`] * params.cfg[`u${type}l`] / 100)) return this.showLang('alarm.type.voltage.under');
      if (params.content[`u${type}`] > (params.cfg[`u${type}r`] * params.cfg[`u${type}h`] / 100)) return this.showLang('alarm.type.voltage.over');
      return this.showLang('com.state.normal');
    },
    switchStatus: function (params) {
      if (params.content.hv == 1) return this.showLang('com.op.hand');
      if (params.content.rv == 1) return this.showLang('com.op.remote');
      if (params.content.tv == 1) return this.showLang('com.op.time.control');
      return this.showLang('com.op.cease');
    },
    waterState: function (params) {
      if (params.content.s3 == params.content.a3) return  this.showLang('alarm.type.water.3');
      if (params.content.s2 == params.content.a2) return  this.showLang('alarm.type.water.2');
      if (params.content.s1 == params.content.a1) return  this.showLang('alarm.type.water.1');
      return this.showLang('com.state.normal');
    }
  }
}
</script>
<style scoped>
td {
  height: 40px;
  white-space: nowrap;
  width: 120px;
}
.main-header {
  background-color: #4781bf;
  border-radius: 6px 6px 0px 0px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  width: 267px;
  height: 32px;
  /* text-align: left;
  padding-left: 15px !important;
  font-weight: bold; */
}
.sub-header {
  background-color: #cfe3f7;
  /* background: #CFE3F7; */
  height: 46px;
  text-align: center;
}
.detail-header {
  /* background-color: #F2F2F2; */
  background-color: #d7e3ef;
  text-align: center;
}
.branch-title {
  padding-top: 5px;
}
.history-item {
  /* border-width: 1px;
  border-collapse: collapse;
  border-color: cyan; */
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
}
/* .table-data tr{
  padding: 8px;
  border-width: 1px;
  border-collapse: collapse;
  border-color: cyan;
} */
.table-data td {
  /* background-color: darkcyan; */
  padding: 5px 8px;
  height: 40px;
  white-space: nowrap;
  width: 120px;
  border-collapse: collapse;
  /* border: solid 1px #91CBD9; */
}
.active {
  background-color: #f2f6fa;
}
</style>