<template>
  <div class="list-container">
    <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :key="dataRefresh" :data="showList" :height="tabHeight" :header-row-class-name="setHeaderRowClass"  align="center" :header-row-style="{textAlign: 'center'}" :row-config="{isHover: true}">
      <vxe-colgroup title="" width="120">
        <vxe-column  type="seq" width="60"></vxe-column>
        <vxe-column field="name" :title="showLang('com.site.name')">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
      </vxe-colgroup>
      <vxe-colgroup :title="showLang('com.device.box')" v-if="hasPowerBox">
        <vxe-column field="online" :title="showLang('com.state.onLine.status')" class-name="cell-text-center">
          <template #default="{ row }">
            <img :src="row.online ? img.light.road.tree.online : img.light.road.tree.offline" />
          </template>
        </vxe-column>
        <vxe-column field="alarm" :title="showLang('com.state.police.status')" class-name="cell-text-center">
          <template #default="{ row }">
            <img :src="row.alarm ? img.light.road.tree.alarm : img.light.road.tree.offline" />
          </template>
        </vxe-column>
        <vxe-column field="running" :title="showLang('com.state.lighting.status')" class-name="cell-text-center">
          <template #default="{ row }">
            <img :src="row.running ? img.light.road.tree.online : img.light.road.tree.offline" />
          </template>
        </vxe-column>
        <vxe-column field="lastTime" :title="showLang('com.ins.time.last')" width="155">
          <template #default="{ row }">
            {{row.lastTime}}
          </template>
        </vxe-column>
        <vxe-column  :title="showLang('com.data.detail')" class-name="cell-text-center">
          <template #default="{ row }">
            <a href="javascript:void(0);" @click="viewDetail(row, 'power')">{{showLang('com.data.detail')}}</a>
          </template>
        </vxe-column>
      </vxe-colgroup>
      <vxe-colgroup :title="showLang('com.device.lamp')" v-if="hasLight">
        <vxe-column :title="showLang('com.lamp.total')" class-name="cell-text-center">
          <template #default="{ row }">
            {{row.lightCount}}
          </template>
        </vxe-column>
        <vxe-column :title="showLang('com.state.onLine')" class-name="cell-text-center">
          <template #default="{ row }">
            <span class="color1">{{row.lightOnline}}</span>
          </template>
        </vxe-column>
        <vxe-column :title="showLang('com.right.nav.alarm')" class-name="cell-text-center">
          <template #default="{ row }">
            <span class="color2">{{row.lightAlarm}}</span>
          </template>
        </vxe-column>
        <vxe-column :title="showLang('com.state.lighting')" class-name="cell-text-center">
          <template #default="{ row }">
            <span class="color3">{{row.lightRunning}}</span>
          </template>
        </vxe-column>
        <vxe-column :title="showLang('com.ins.time.last')" width="155">
          <template #default="{ row }">
            {{row.lightTime}}
          </template>
        </vxe-column>
        <vxe-column :title="showLang('com.data.detail')" class-name="cell-text-center">
          <template #default="{ row }">
            <a v-if="row.lightCount > 0" href="javascript:void(0);" @click="viewDetail(row, 'light')">{{showLang('com.data.detail')}}</a><span v-else>-</span>
          </template>
        </vxe-column>
      </vxe-colgroup>
    </vxe-table>
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'StationBaseIndex',
  components: {
  },
  props: {
    tabHeight: {
      type: Number,
      default: 600
    }
  },
  data() {
    return {
      hasPowerBox: false,
      hasLight: false,
      list: [],
      showList: [],
      dataRefresh: 0,
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedNode']),
  },
  watch: {
    selectedNode() {
      this.initData();
    }
  },
  mounted: function () {
    this.initData();
  },
  destroyed: function () {
  },
  methods: {
    sortChange(data) {
      let order = data.order;
      if (order == null) {
        this.$set(this, 'showList', this.list);
        return false;
      }
      switch (data.column.label) {
        case "在线状态"://online
          this.showList = this.sortLetter(order, 'online')
          break;
        case "报警状态"://alarm
          this.showList = this.sortLetter(order, 'alarm')
          break;
        case "亮灯状态"://running
          this.showList = this.sortLetter(order, 'running')
          break;
        case "总数量"://lightCount
          this.showList = this.sortArray(order, 'lightCount')
          break;
        case "在线"://lightOnline
          this.showList = this.sortArray(order, 'lightOnline')
          break;
        case "报警"://lightAlarm
          this.showList = this.sortArray(order, 'lightAlarm')
          break;
        case "亮灯"://lightRunning
          this.showList = this.sortArray(order, 'lightRunning')
          break;
      }
    },
    sortLetter(judge, field) {
      this.showList.forEach(el => {
        el.index = el[field] ? 1 : -1;
      });
      let list = this.showList.sort(function (a, b) {
        if (judge == 'ascending') {
          return b.index - a.index
        } else {
          return a.index - b.index
        }
      })
      return list;
    },
    sortArray(judge, field) {
      let list = this.showList.sort(function (a, b) {
        if (judge == 'ascending') {
          return b[field] - a[field]
        } else {
          return a[field] - b[field]
        }
      })
      return list;
    },
    initData: function () {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/analyse/StationList`, { groupId, stationId }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.hasPowerBox = res.data.hasPower;
          this.hasLight = res.data.hasLight;
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'showList', res.data.list);
          this.dataRefresh++;
        }
      });
    },
    setHeaderRowClass: function ({ $rowIndex }) {
      return `header-row-class-${$rowIndex}`
    },
    viewDetail: function (params, tabName) {
      this.$store.commit('group/selectNodeById', { nodeId: params.id, type: this.productCodes.station });
      this.$store.commit('auth/switchStationTabName', tabName);
    },
  }
}
</script>
<style>
.cell-text-center {
  text-align: center !important;
}
.header-row-class-0 {
  background: #4b9cff;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  height: 54px;
  /* border-radius: 6px 6px 0px 0px; */
}
.header-row-class-0 > th {
  /* text-align: center !important; */
  background: #4b9cff !important;
  /* border-radius: 6px 6px 0px 0px; */
}
.header-row-class-0 > th > .cell {
  text-align: center !important;
  background: #4b9cff;
  /* border-radius: 6px 6px 0px 0px; */
}
.header-row-class-1 {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400 !important;
  color: #324252;
  height: 54px;
}
.header-row-class-1 > th > .cell {
  text-align: center !important;
  /* background: #4B9CFF;
  border-radius: 6px 6px 0px 0px; */
}
</style>
<style scoped>
.header-back {
  background: #4b9cff;
  border-radius: 6px 6px 0px 0px;
}
.header-text {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  height: 54px;
}
.list-container table {
  width: 100%;
  height: 93px;
  margin-bottom: 15px;
  padding-bottom: 50px;
}
.split-td {
  border-right: solid 1px #d0d9e9 !important;
}
.table-td {
  width: 115px;
  text-align: center;
  box-sizing: border-box;
  border-right: 1px solid #d0d9e9;
  border-bottom: 1px solid #d0d9e9;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  height: 54px;
}
.list-container table tr:last-child td {
  border-bottom: none;
}
.list-container table tr td:last-child {
  border-right: none;
}
.color1 {
  color: #2bd58c;
}
.color2 {
  color: #fec71e;
}
.color3 {
  color: #ff5d4c;
}
.color4 {
  color: #b153e7;
}
</style>