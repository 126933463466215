<template>
  <Modal v-model="showModal" :mask-closable="false" width="800px">
    <div slot="header">{{showLang('gis.set.location.info')}}</div>
    <div>{{showLang('gis.current.lng')}}：{{pos.lng}} <span class="ml20">{{showLang('gis.current.lat')}}{{pos.lat}}</span></div>
    <div class="demo-input-suffix mt20">
      <Input v-model="name" :placeholder="showLang('save.search.for')" style="width: 200px"></Input>
      <span class="search-title ml20">{{showLang('com.site.device')}}：</span>
      <Select v-model="selectId" :placeholder="showLang('save.select')" style="width: 200px">
        <Option v-for="item in options" :key="item.type" :label="showLang(item.lang)" :value="item.type">
        </Option>
      </Select>
      <Button type="primary" class="ml20" @click="queryPosItem">{{showLang('com.op.query')}}</Button>
    </div>
    <div class="table-box mt20">
      <vxe-table  :empty-text="showLang('com.data.no')" border show-overflow ref="xTable1" height="400" :row-config="{isHover: true}" :sort-config="{trigger: 'cell'}" :data="list">
        <vxe-column type="radio" width="60">
          <template #header>
            <vxe-button type="text" @click="clearRadioRowEevnt">{{showLang('com.op.cancel')}}</vxe-button>
          </template>
        </vxe-column>
        <vxe-column field="name" :title="showLang('com.tab.title')" sortable></vxe-column>
        <vxe-column field="type" :title="showLang('com.state.type')" width="250">
          <template #default="{ row }">
            {{typeName(row.type)}}
          </template>
        </vxe-column>
        <vxe-column field="id" title="id"></vxe-column>
      </vxe-table>
      <div class="mt10" style="text-align: right;">
        <Page :total="total" show-total :page-size-opts="[20, 50, 100, 200]" @on-change="pageIndexChange" @on-page-size-change="pageSizeChange" />
      </div>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'ModalSetDevicePos',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    productList: {
      type: Array,
      default() {
        return [
          //格式：{
          // type: 0, //对象类型：0是站点，其他即属性类型，199是单灯，299是光控，及灯杆相关设备
          // id: 4, //对象ID
          // name: "光控：光控一", //对象名称
          //}
        ];
      }
    },
    pos: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      productId: '',
      gisTypes: {
        0: '站点',
        10: '线缆',
        14: '灯杆',
        199: '单灯',
        299: '光控',
        301: '视频监控',
        302: 'LED屏',
        303: '环境监测',
        304: '广播',
        305: '可视对讲',
        306: 'WIFI',
        307: '充电桩',
        309: '电源网关',
        310: '智能垃圾桶',
        311: '智能锁',
        312: '手机充电',
        320: '智能井盖',
      },
      name: "",
      selectId: -1,
      options: [
        { name: "全部",lang:'com.state.all', type: -1 },
        { name: "配电箱",lang:'com.device.box', type: 0 },
        { name: "灯杆",lang:'com.device.pole', type: 14 },
        { name: "单灯",lang:'com.device.lamp', type: 199 },
        { name: "光控",lang:'com.device.lightControl', type: 299 },
        { name: "电缆",lang:'com.device.cable', type: 10 },
      ],
      list: [],
      total: 0,
      pageIndex: 1,
      pageSize: 20,
      selectRow: null,
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.productId = '';
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['poleTypeNames']),
  },
  mounted: function () {
    // this.getProjects();
  },
  methods: {
    pageIndexChange: function (index) {
      this.pageIndex = index;
      this.queryPosItem();
    },
    pageSizeChange: function (size) {
      this.pageIndex = 1;
      this.pageSize = size;
      this.queryPosItem();
    },
    clearRadioRowEevnt() {
      this.selectRow = null
      this.$refs.xTable1.clearRadioRow()
    },
    typeName(key) {
      let name = "";
      switch (key) {
        case 0:
          name= this.showLang('com.device.box')
          break;
        case 14:
          name= this.showLang('com.device.pole')
          break;
        case 199:
          name= this.showLang('com.device.lamp')
          break;
        case 299:
          name= this.showLang('com.device.lightControl')
          break;
        case 10:
          name= this.showLang('com.device.cable')
          break;
      }
      return name;
    },
    queryPosItem() {
      // console.log('get pos item', this.pos)
      let ajaxData = {
        "type": this.selectId * 1,
        "name": this.name,
        "index": this.pageIndex,
        "size": this.pageSize,
        groupId: this.pos.groupId,
        stationId: this.pos.stationId
      }
      this.$axios.post(`//${this.domains.trans}/station/gis/QueryPosItem`, ajaxData).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.total = res.data.cnt;
          this.$set(this, 'list', res.data.result);
        }
      });
    },
    ok: async function () {
      if (this.$refs.xTable1.getRadioRecord() == null) {
        this.$Message.warning(this.showLang('save.move.dev'));
        return;
      }
      let ps = this.$refs.xTable1.getRadioRecord()
      // console.log('get selected row', ps)
      let res = await this.$axios.post(`//${this.domains.trans}/station/gis/SetPos`, {
        type: ps.type,
        id: ps.id,
        lat: this.pos.lat,
        lng: this.pos.lng
      });
      // console.log('pos saved', res)
      if (res.code == 0) {
        this.$emit('posSaved', { success: true, type: ps.type, id: ps.id, lat: this.pos.lat, lng: this.pos.lng });
      }

    },
    cancel() {
      this.showModal = false;
      this.$emit('posSaved', { success: false });
    },
  }
}
</script>
<style scoped>
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>