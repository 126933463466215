<template>
  <div class="map-container">
    <baidu-map class="map" :ak="ak" :center="center" :map-type="mapType" :double-click-zoom="false" @ready="mapReady" :zoom="center.zoom"
      :scroll-wheel-zoom="true" @zoomend="zoomend" @dragend="dragend" @click="mapClick">
      <bm-navigation v-if="map" anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-navigation>
      <bm-control v-if="map" :offset="{width: 20, height: 20}">
        <div class="map-tool-container">
          <div :class="['item', mapType == 'BMAP_NORMAL_MAP' ? 'active' : '']" @click="switchMapType('BMAP_NORMAL_MAP')">
            {{showLang('gis.show.type.map')}}</div>
          <div :class="['item', mapType == 'BMAP_SATELLITE_MAP' ? 'active' : '']" @click="switchMapType('BMAP_SATELLITE_MAP')">
            {{showLang('gis.show.type.moon')}}</div>
        </div>
      </bm-control>
      <bm-control v-if="map" :offset="{width: 20, height: 20}" anchor="BMAP_ANCHOR_TOP_RIGHT">
        <div class="map-data-stat">
          <div class="map-data-type">
            <div class="map-data-top"><span><i class="map-data-drop"></i>{{showLang('com.device.box')}}</span><i>{{roadTotal.power.total}}</i></div>
            <div class="map-data-son"><img :src="img.mapPowerOnline" alt=""><span class="map-data-name">{{showLang('com.state.onLine')}}</span><span
                class="map-data-num">{{roadTotal.power.alarm}}</span>
            </div>
            <div class="map-data-son"><img :src="img.mapPowerError" alt=""><span class="map-data-name">{{showLang('com.right.nav.alarm')}}</span><span
                class="map-data-num">{{roadTotal.power.online}}</span>
            </div>
            <div class="map-data-son"><img :src="img.mapPowerRepair" alt=""><span class="map-data-name">{{showLang('com.state.service')}}</span><span
                class="map-data-num">{{roadTotal.power.repair}}</span>
            </div>
          </div>
          <div class="map-data-type">
            <div class="map-data-top"><span><i class="map-data-drop"></i>{{showLang('com.device.lamp')}}</span><i>{{roadTotal.light.total}}</i></div>
            <div class="map-data-son"><img :src="img.light.carat.gis.LightsOn" alt=""><span
                class="map-data-name">{{showLang('com.but.open.lamp')}}</span><span class="map-data-num">{{roadTotal.light.runing}}</span></div>
            <div class="map-data-son"><img :src="img.light.carat.gis.LightsPolice" alt=""><span
                class="map-data-name">{{showLang('com.state.police')}}</span><span class="map-data-num">{{roadTotal.light.alarm}}</span></div>
          </div>
          <div class="map-data-type">
            <div class="map-data-top"><span><i class="map-data-drop"></i>{{showLang('com.device.lightControl')}}</span><i>{{roadTotal.lux.total}}</i>
            </div>
          </div>
          <div class="map-data-type">
            <div class="map-data-top"><span><i class="map-data-drop"></i>{{showLang('com.device.cable')}}</span><i>{{roadTotal.line.total}}</i></div>
          </div>
        </div>
      </bm-control>
      <bm-control v-if="map" :offset="{width: 140, height: 20}">
        <div class="map-tool-container">
          <template v-for="(item,index) in deviceArray">
            <div :class="['item', deviceType == item.type? 'active' : '']" @click="switchDeviceType(item.type)" :key="index">
              {{item.lang?showLang(item.lang):item.name}}</div>
          </template>
        </div>
      </bm-control>
      <bm-control v-if="map && funCodes('gsp')" :offset="{width: 400, height: 20}">
        <div class="map-tool-drag">
          <span style="margin-right: 5px">{{showLang('gis.set.position')}}</span>
          <i-switch v-model="enableMoveDevice" size="large" true-color="#1B5FA8" false-color="#6D8194" :title="showLang('gis.switch.dev.position')">
            <span slot="open">{{showLang('com.state.open')}}</span>
            <span slot="close">{{showLang('com.state.prohibit')}}</span>
          </i-switch>
        </div>
      </bm-control>
      <bm-control v-if="map" :offset="{width: 550, height: 20}">
        <div class="map-tool-container">
          <div class="map-tool-drag" style="margin-right: 5px" v-if="deviceType == 'lux' && funCodes('gsa')">
            <span style="margin-right: 5px" @click="setClose()">{{showLang('gis.set.optical')}}</span>
          </div>
          <div class="map-tool-drag" v-if="deviceType == 'cable' && funCodes('gsl')">
            <span style="margin-right: 5px" @click="setCable()">{{showLang('gis.set.cable')}}</span>
          </div>
        </div>
      </bm-control>
      <bm-marker v-if="enableMoveDevice && moveDevicePos.lat != 0 && moveDevicePos.lng != 0" :key="0" :dragging="false"
        :position="{lng: moveDevicePos.lng, lat: moveDevicePos.lat}">
        <bm-label :content="showLang('gis.set.target')" :offset="{width: -60, height: 35}"
          :labelStyle="{width: '150px', textAlign:'center', backgroundColor: 'transparent', color: 'red', border: 0, fontSize : '12px'}" />
      </bm-marker>
      <template v-if="isZoomType!='group'">
        <template v-for="item in showItems">
          <template v-if="item.type == 299">
            <bm-marker :key="`${item.id}_${item.type}`" :dragging="false" :icon="{url: getImgType(item), size: {width: 26, height: 36}}"
              :position="{lng: item.lng, lat: item.lat}" :name="item.name" @click="showMakerInfo(item)">
              <bm-label :content="item.name" :offset="{width: -60, height: 35}"
                :labelStyle="{width: '150px', textAlign:'center', backgroundColor: 'transparent', color: 'red', border: 0, fontSize : '12px'}" />
            </bm-marker>
            <bm-circle :key="`${item.id}_${item.type}_circle`" :center="{lng: item.lng, lat: item.lat}" :radius="parseInt(item.path.radius)"
              stroke-color="blue" :stroke-opacity="0.3" :stroke-weight="1"></bm-circle>
          </template>
          <template v-else-if="item.type == 10">
            <bm-polyline :path="item.path.list" :key="`${item.id}_${item.type}`" stroke-color="blue" :clicking="true" :stroke-opacity="0.5"
              :stroke-weight="2" :editing="editLineId == item.id" @lineupdate="updatePolylinePath($event,item)"></bm-polyline>
          </template>
          <template v-else>
            <bm-marker :key="`${item.id}_${item.type}`" :dragging="false" :icon="{url: getImgType(item), size:getSizeType(item)}"
              :position="{lng: item.lng, lat: item.lat}" :name="item.name" @click="showMakerInfo(item)">
              <bm-label :content="item.name" :offset="getOffsetType(item)"
                :labelStyle="{width: '150px', textAlign:'center', backgroundColor: 'transparent', color: 'red', border: 0, fontSize : '12px'}" />
            </bm-marker>
          </template>
        </template>
      </template>
      <template v-else>
        <div v-for="item,index in showItems" :key="gainDate(index)">
          <MyOverLay :position="{lng: item.lng, lat: item.lat}" :item="item" @overLayBut="overLayBut" />
        </div>
      </template>
    </baidu-map>
    <ModalSetDevicePos v-model="moveDevicePos.show" :pos="moveDevicePos" :productList="productList" @posSaved="posSaved" />
    <ModalMakerInfo v-model="showMakerInfoModal" :item="editItem" />
    <!-- 光控设置 -->
    <div v-if="luxShowSetRadius && deviceType == 'lux'" class="popupShow">
      <setLuxRadius :productList="productList" @radiusChanged="radiusChanged" @close="close" @centerChanged="centerChanged" />
    </div>
    <!-- 电缆设置 -->
    <div v-if="cableShowSetNew && deviceType == 'cable'" class="popupShow">
      <setNewCable :deviceList="cablesData" @startEdit="startEdit" @lineSave="lineSave" @lineDelete="lineDelete" @close="close"
        @centerChanged="centerChanged" />
    </div>
    <Modal v-model="getCableSpot">
      <div slot="header">{{showLang('gis.set.cable.map')}}</div>
      <Button type="primary" @click="getCableSpotBut(false)">{{showLang('gis.set.end')}}</Button>
      <div slot="footer">
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalMakerInfo from './ModalMakerInfo'
import {
  BaiduMap,
  // BmInfoWindow,
  BmLabel,
  BmControl,
  BmNavigation,
  BmMarker,
  BmCircle,
  BmPolyline,
  //  BmOverlay
} from '@/components/vue-baidu-map/components'
import ModalSetDevicePos from '@/components/map/common/ModalSetDevicePos'
import setLuxRadius from '../common/setLuxRadius'
import setNewCable from '../common/setNewCable'
import MyOverLay from './MyOverLay'
export default {
  name: 'MapDevice',
  components: {
    BaiduMap,
    // BmMarkerClusterer,
    BmMarker,
    BmCircle,
    // BmOverlay,
    BmLabel,
    BmControl,
    BmNavigation,
    BmPolyline,
    // TempHistoryData,
    setLuxRadius,
    setNewCable,
    ModalSetDevicePos,
    ModalMakerInfo,
    MyOverLay,
    // MutiPowerAlarm,
  },
  props: {
    gainDeviceType: {
      type: Array,
      default() { return []; }
    }
  },
  data() {
    return {
      editItem: {},
      showMakerInfoModal: false,
      search: {
        type: 'normal', //status, alarm
        normal: {
          ownerId: 0,
          maintenanceId: 0,
          roadName: '',
          deviceCode: '',
        },
        status: {},
        alarm: {}
      },
      statusActive: "",
      deviceType: 'all',
      deviceArray: [
        { name: "全部", type: "all", lang: 'com.state.all' },
        { name: "配电柜", type: "powerbox", lang: 'com.device.box' },
        { name: "单灯", type: "light", lang: 'com.device.lamp' },
        { name: "光控", type: "lux", lang: 'com.device.lightControl' },
        { name: "电缆", type: "cable", lang: 'com.device.cable' },
      ],
      mapType: 'BMAP_NORMAL_MAP',
      ak: 'MhPU9AQpbKZ0kyQf7bWAv7BvwoKOax88',
      map: null,
      BMap: null,
      mapStyle: {
        styleJson: require('@/assets/custom_map_config.json'),
      },
      center: { lng: 113.828333, lat: 22.770866, zoom: 17 },
      enableMoveDevice: false,
      showInfo: false,
      showInfoType: '',
      selected: { id: 0 },
      showBoxData: {},//配电箱数据
      pos: {
        title: '',
        lng: 0,
        lat: 0
      },
      moveDevicePos: {
        show: false,
        lat: 0,
        lng: 0,
        groupId: 0,
        stationId: 0
      },
      productList: [],
      luxRules: [], //所有光控规则
      luxShowSetRadius: false,
      showSetRadius: false,
      luxProductId: 0,
      cables: [],//电缆
      cableShowSetNew: false,//显示新增电缆
      isSetCable: false,//开启电缆设置
      getCableSpot: false,
      showCableData: {
        id: 0,
        length: 0,
      },
      cableSpotData: { start: { lng: "", lat: "" }, end: { lng: "", lat: "" }, now: { lng: "", lat: "" } },
      gisItems: [],
      showItems: [],
      editLineId: 0,
      pointsData: [],
      pointsData1: [],
      viewMapData: {
        zoom: 17, //缩放值
        groupId: 0, //分组ID
        stationId: 0, //站点ID
        latmin: 0,
        latmax: 0,
        lngmin: 0,
        lngmax: 0,
        type: -1, //-1全部 0配电箱
      },
      roadTotal: {//统计消息
        power: {
          total: 0,
          online: 0,
          alarm: 0,
          repair: 0
        },
        light: {//单灯
          total: 0,
          runing: 0,
          alarm: 0
        },
        lux: {//光控
          total: 0
        },
        line: {//电缆
          total: 0
        }
      },
      isZoomType: "group",
    }
  },
  created: function () {
    this.$set(this, 'center', {
      lat: this.config.lat,
      lng: this.config.lng,
      zoom: 17,
    })
  },
  watch: {
    selectedNode() {
      if (this.map) {
        this.initShowItems();
        this.queryRoadTotal();
        this.handleSelectedNode();
        setTimeout(() => {
          this.viewMapAjax();
        }, 300);
      }
    },
    deviceType() {
      if (this.map) {
        this.viewMapAjax();
      }
    },
  },
  computed: {
    ...mapState('auth', ['user', 'config']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    cablesData: function () {
      return this.gisItems.filter(p => p.type == 10);
    },
    powerboxes: function () {
      return this.gisItems.filter(p => p.type == 0);
    },
    lights: function () {
      return this.gisItems.filter(p => p.type == 199);
    },
    luxes: function () {
      return this.gisItems.filter(p => p.type == 299);
    },
  },
  mounted: function () {
    this.initGisItems();
    this.arrangeDeviceType();
    this.queryRoadTotal();
  },
  methods: {
    trrArr(tree) {
      const obj = [];
      tree.forEach((item) => {
        obj.push(item)
        if (item.children) {
          obj.push(...this.trrArr(item.children))
        }
      });
      return obj
    },
    handleSelectedNode() {
      if (this.selectedNode.type == "Station") {
        this.center.lat = this.selectedNode.lat;
        this.center.lng = this.selectedNode.lng;
        this.center.zoom = 17;
      } else {
        let trrArr = JSON.parse(JSON.stringify(this.selectedNode))
        let arr = this.trrArr([trrArr]);
        let arrStation = arr.filter(p => p.type == 'Station');
        if (arrStation.length > 0) {
          this.center.lat = arrStation[0].lat;
          this.center.lng = arrStation[0].lng;
          this.center.zoom = 17;
        }
      }
    },
    gainDate(el) {
      return Math.round(Math.random() * 10000000000000) + el;
    },
    overLayBut(el) {
      this.center.lat = el.lat;
      this.center.lng = el.lng;
      this.center.zoom = 17;
      this.viewMapAjax();
    },
    queryRoadTotal() {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/gis/QueryRoadTotal`, { groupId, stationId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'roadTotal', res.data)
        }
      });
    },
    viewMapAjax() {
      if (this.map == null) return;
      let bounds = this.map.getBounds()
      this.viewMapData.latmin = bounds.de;
      this.viewMapData.latmax = bounds.be;
      this.viewMapData.lngmin = bounds.Se;
      this.viewMapData.lngmax = bounds.Me;
      this.viewMapData.zoom = this.center.zoom;
      this.viewMapData.groupId = 0;
      this.viewMapData.stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        this.viewMapData.stationId = this.selectedNode.id;
      } else {
        this.viewMapData.groupId = this.selectedNode.id;
      }
      switch (this.deviceType) {
        case "all":
          this.viewMapData.type = -1
          break;
        case "powerbox":
          this.viewMapData.type = 0
          break;
        case "light":
          this.viewMapData.type = 199
          break;
        case "lux":
          this.viewMapData.type = 299
          break;
        case "cable":
          this.viewMapData.type = 10
          break;
      }
      this.$axios.post(`//${this.domains.trans}/station/gis/QueryRoadItem`, this.viewMapData).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'isZoomType', res.data.type);
          this.$set(this, 'showItems', res.data.list);
          // if (!this.BMap) return;
          // let minlat = 90, minlng = 180, maxlat = 0, maxlng = 0;
          // let data = this.showItems;
          // for (let item of data) {
          //   if (item.lat <= 0 || item.lng <= 0) continue;
          //   if (item.lat < minlat) minlat = item.lat;
          //   if (item.lat > maxlat) maxlat = item.lat;
          //   if (item.lng < minlng) minlng = item.lng;
          //   if (item.lng > maxlng) maxlng = item.lng;
          // }
          // this.center.lat = data.length == 0 ? this.config.lat : (minlat + maxlat) / 2;
          // this.center.lng = data.length == 0 ? this.config.lng : (minlng + maxlng) / 2;
        }
      });
    },
    zoomend(e) {
      this.center.zoom = e.target.getZoom()
      if (this.center.zoom == 10 || this.center.zoom == 11) {
        this.center.zoom = 12;
      }
      this.viewMapAjax();
    },
    dragend() {
      this.viewMapAjax();
    },
    startEdit: function (params) {
      this.editLineId = params.id;
      let items = this.gisItems.filter(p => p.type == 10 && p.id == params.id);
      if (items.length == 0) return;
      if (items[0].path.list.length == 0) {
        let pbs = this.gisItems.filter(p => p.type == 0 && p.id == items[0].sid);
        if (pbs.length == 0) {
          this.$Message.warning('线缆关联的配电柜不存在');
          return;
        }
        items[0].path.list.push({ lat: pbs[0].lat, lng: pbs[0].lng });
        items[0].path.list.push({ lat: pbs[0].lat + 0.001, lng: pbs[0].lng + 0.001 });
      }
      let c = {
        lat: items[0].path.list[0].lat,
        lng: items[0].path.list[0].lng,
        zoom: 18,
      }
      this.$set(this, 'center', c);
    },
    lineSave: async function (params) {
      let items = this.gisItems.filter(p => p.type == 10 && p.id == params.id);
      if (items.length == 0) return;
      let res = await this.$axios.post(`//${this.domains.trans}/station/gis/SetPowerLine`, { id: params.id, list: items[0].path.list });
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return;
      }
      this.$Message.info(this.showLang('com.tips.set.success'));
      this.close();
    },
    lineDelete: function (params) {
      for (let i = 0; i < this.gisItems.length; i++) {
        if (this.gisItems[i].id == params.id) {
          this.gisItems.splice(i, 1);
          break;
        }
      }
      for (let i = 0; i < this.showItems.length; i++) {
        if (this.showItems[i].id == params.id) {
          this.showItems.splice(i, 1);
          break;
        }
      }
    },
    close: function () {
      this.luxShowSetRadius = false;
      this.cableShowSetNew = false;
    },
    initGisItems: function () {
      let groupId = 0, stationId = 0;
      this.$axios.post(`//${this.domains.trans}/station/gis/QueryItem`, { groupId, stationId }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'gisItems', res.data);
          this.initShowItems();
        }
      });
    },
    initShowItems: function () {
      let items = [];
      if (this.selectedNode.type != this.productCodes.station) {
        let sids = this.selectedDevices.map(p => p.id);
        items = this.gisItems.filter(p => sids.indexOf(p.sid) != -1);
      } else {
        items = this.gisItems.filter(p => p.sid == this.selectedNode.id);
      }
      if (this.deviceType == 'powerbox') {
        items = items.filter(p => p.type == 0);
      } else if (this.deviceType == 'light') {
        items = items.filter(p => p.type == 199);
      } else if (this.deviceType == 'lux') {
        items = this.gisItems.filter(p => p.type == 299);
      } else if (this.deviceType == 'cable') {
        items = items.filter(p => p.type == 10);
      } else if (this.deviceType == 'all') {
        items.push(...this.gisItems.filter(p => p.type == 299));
      }

    },
    centerChanged: function (params) {
      let c = {
        lat: params.lat,
        lng: params.lng,
        zoom: params.zoom,
      }
      this.$set(this, 'center', c);
    },
    radiusChanged: function (params) {
      let items = this.gisItems.filter(p => p.type == 299 && p.id == params.id);
      if (items.length > 0) {
        items[0].path.radius = parseInt(params.radius);
      }
    },
    arrangeDeviceType() {//判断显示的类型
      if (this.gainDeviceType.length > 0) {
        this.$set(this, 'deviceArray', this.gainDeviceType);
      }
    },
    isShowDeviceType(type) {//判断显示类型
      let isShow = false;
      let isType = this.deviceArray.filter(p => p.type == type).length > 0;
      switch (type) {
        case "powerbox":
          if ((this.deviceType == 'all' || this.deviceType == 'powerbox') && this.powerboxes.length > 0 && isType) {
            isShow = true
          }
          break;
        case "light":
          if ((this.deviceType == 'all' || this.deviceType == 'light') && this.lights.length > 0 && isType) {
            isShow = true
          }
          break;
        case "lux":
          if ((this.deviceType == 'all' || this.deviceType == 'lux') && this.luxes.length > 0 && isType) {
            isShow = true
          }
          break;
        case "cable":
          if ((this.deviceType == 'all' || this.deviceType == 'cable') && this.cablesData.length > 0 && isType) {
            isShow = true
          }
          break;
      }
      return isShow;
    },
    getCableSpotBut() {
      let el = this.cablesData.filter(p => p.id == this.showCableData.id)[0];
      let data = {
        lng: this.cableSpotData.now.lng.toFixed(6) * 1,
        lat: this.cableSpotData.now.lat.toFixed(6) * 1,
      }
      el.extraData.gis.route.push(data);
      this.showCableData.length = el.extraData.gis.route.length;
      this.getCableSpot = false;
    },
    setCable() {
      this.cableShowSetNew = true;
    },
    setCableData(el) {
      this.showCableData.id = el.id;
      this.showCableData.length = el.length;
    },
    setRadius(el) {
      let ls = this.luxes.filter(p => p.id == el.id);
      ls[0].extraData.gis.radius = el.radius * 1;
      this.luxProductId = el.id;
    },
    updatePolylinePath(e, item) {
      this.$set(item.path, 'list', e.target.getPath());
    },
    //设置半径
    setClose() {
      this.luxShowSetRadius = true;
      let prods = [];
      for (let lux of this.gisItems) {
        if (lux.type != 299) continue;
        prods.push({ type: this.productCodes.lux, id: lux.id, name: `光控：${lux.name}`, lat: lux.lat, lng: lux.lng, path: lux.path, paramId: 0 });
      }
      this.$set(this, 'productList', prods);
    },
    isShow(item) {
      let is = false, judge2 = false, judge1 = false;
      // this.search.type  //normal 常规 //status状态
      switch (this.search.type) {
        case 'normal':
          if (this.search.normal.roadName == '' || (item.location != null && item.location.indexOf(this.search.normal.roadName) != -1)) {
            judge1 = true;
          }
          if (this.search.normal.deviceCode == '' || ('' + item.id).indexOf(this.search.normal.deviceCode) != -1 || item.name.indexOf(this.search.normal.deviceCode) != -1) {
            judge2 = true;
          }
          if (judge1 == judge2 && judge2) {
            is = true;
          }
          break;
        case 'status':
          if (this.statusActive == '') {
            is = true;
          }
          if (this.statusActive == '报警' && item.isAlarm) {
            is = true;
          }
          if (this.statusActive == '运行' && item.isOnline) {
            is = true;
          }
          if (this.statusActive == '离线' && !item.isOnline) {
            is = true;
          }
          break;
      }
      return is
    },
    mapClick: function (e) {
      if (this.enableMoveDevice) {
        let prods = [];
        for (let p of this.showItems) {
          prods.push({ type: p.type, id: p.id, name: p.name });
        }
        this.$set(this, 'productList', prods);
        let groupId = 0, stationId = 0;
        if (this.selectedNode.type == 'root' || this.selectedNode.type == 'group') {
          groupId = this.selectedNode.id;
        } else {
          stationId = this.selectedNode.id;
        }
        this.moveDevicePos.lat = e.point.lat;
        this.moveDevicePos.lng = e.point.lng;
        this.moveDevicePos.groupId = groupId;
        this.moveDevicePos.stationId = stationId;
        this.moveDevicePos.show = true;
      } else if (this.cableShowSetNew && this.deviceType == 'cable' && this.showCableData.length < 2 && this.cablesData.filter(p => p.id == this.showCableData.id).length > 0) {
        this.getCableSpot = true;
        this.cableSpotData.now.lat = e.point.lat * 1;
        this.cableSpotData.now.lng = e.point.lng * 1;
      }
    },
    posSaved: function (params) {
      if (params.success) {
        let ps = this.gisItems.filter(p => p.type == params.type && p.id == params.id);
        if (ps.length == 0) return;
        ps[0].lat = params.lat;
        ps[0].lng = params.lng;
      }
      // this.enableMoveDevice = false;
      this.moveDevicePos.lat = 0;
      this.moveDevicePos.lng = 0;
      this.moveDevicePos.show = false;
    },
    switchSearchType: function (type) {
      this.search.type = type;
      this.statusActive = type == "normal" ? "" : this.statusActive
    },
    switchDeviceType: function (type) {
      this.deviceType = type;
    },
    switchMapType: function (type) {
      this.mapType = type;
    },
    alarmScroll: function () { },
    getImgType: function (dev) {
      if (dev.type == 0) { //'powerbox'
        if (!dev.online) {
          return this.img.powerbox.status.offline;
        } else if (dev.alarm) {
          return this.img.powerbox.status.alarm;
        } else {
          return this.img.powerbox.status.online;
        }
      } else if (dev.type == 199) { //'light'
        if (dev.single) {//单灯
          if (!dev.online) {
            return this.img.light.map.state.oneOffline;
          } else if (dev.alarm) {
            return this.img.light.map.state.oneAlarm;
          } else {
            return this.img.light.map.state.oneOnline;
          }
        } else {//双灯
          if (!dev.online) {
            return this.img.light.map.state.twoOffline;
          } else if (dev.alarm) {
            return this.img.light.map.state.twoAlarm;
          } else {
            return this.img.light.map.state.twoOnline;
          }
        }

      } else if (dev.type == 299) { //'lux'
        if (!dev.online) {
          return this.img.light.status.offline;
        } else if (dev.alarm) {
          return this.img.light.status.alarm;
        } else {
          return this.img.light.status.online;
        }
      }
    },
    getSizeType(el) {
      let data = { width: 26, height: 36 };
      if (el.type == 199) {
        data = { width: 54, height: 62 }
      }
      return data;
    },
    getOffsetType(el) {
      let data = { width: -60, height: 35 };
      if (el.type == 199) {
        data = { width: -48, height: 60 }
      }
      return data;
    },
    mapReady: function (params) {
      this.map = params.map;
      this.BMap = params.BMap;
      this.map.setMapStyleV2({
        styleJson: this.mapStyle.styleJson
      })
      this.getMapCenter();
    },
    getMapCenter: async function () {
      if (!this.BMap) return;
      let minlat = 90, minlng = 180, maxlat = 0, maxlng = 0;
      let cnt = 0, data = this.showItems;
      for (let item of data) {
        if (item.lat <= 0 || item.lng <= 0) continue;
        if (item.lat < minlat) minlat = item.lat;
        if (item.lat > maxlat) maxlat = item.lat;
        if (item.lng < minlng) minlng = item.lng;
        if (item.lng > maxlng) maxlng = item.lng;
        cnt++;
      }
      let c = {
        lat: cnt == 0 ? this.config.lat : (minlat + maxlat) / 2,
        lng: cnt == 0 ? this.config.lng : (minlng + maxlng) / 2,
        zoom: cnt == 0 ? 12 : this.getZoom(minlat, minlng, maxlat, maxlng)
      }
      c.zoom = 17;// c.zoom < 12 ? 12 : c.zoom;
      this.$set(this, 'center', c);
    },
    getZoom: function (minlat, minlng, maxlat, maxlng) {
      if (!this.BMap) return 17;
      let zooms = [50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 25000, 50000, 100000, 200000, 500000, 1000000, 2000000];//级别18-3对应的比例尺
      let pmin = new this.BMap.Point(minlng, minlat);
      let pmax = new this.BMap.Point(maxlng, maxlat);
      let dis = this.map.getDistance(pmin, pmax);
      for (let i = 0; i < zooms.length; i++) {
        // if (zooms[i] > dis) return 18 - i + 4;
        if (zooms[i] > dis) return 17;
      }
      return 17;
    },
    showMakerInfo: function (params) {
      this.editItem = params;
      this.showMakerInfoModal = true;
    },
  }
}
</script>
<style scoped>
.light-table td {
  padding: 5px 8px;
  height: 40px;
  white-space: nowrap;
  width: 120px;
  border-collapse: collapse;
  border: solid 1px #91cbd9;
}
.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
}
.map {
  width: 100%;
  height: 100%;
  margin: auto;
}
.monitor-info-icon {
  position: absolute;
  left: 20px;
  bottom: 10px;
  width: 40px;
  height: 40px;
  background: rgba(2, 22, 41, 0.9);
  border-radius: 4px;
  z-index: 180;
  cursor: pointer;
}
.map-tool-drag {
  display: flex;
  align-items: center;
  background-color: white;
  height: 26px;
  padding: 0 5px;
  border-radius: 3px;
  border: solid 1px #1b5fa8;
  cursor: pointer;
}
.map-tool-container {
  display: flex;
  border-radius: 3px;
  background-color: white;
  height: 26px;
}
.map-tool-container .item {
  height: 26px;
  background-color: white;
  color: black;
  cursor: pointer;
  border: solid 1px #1b5fa8;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  padding: 0 5px;
}
.map-tool-container .active {
  background-color: #1b5fa8;
  color: white;
}
.search-area {
  width: 251px;
  height: 120px;
  background: rgba(2, 22, 41, 0.9);
  border-radius: 6px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  padding: 10px;
}
.search-area-title {
  display: flex;
  border-bottom: solid 1px #3b4d63;
}
.search-area-type {
  flex: auto;
  text-align: center;
  padding-bottom: 5px;
  cursor: pointer;
}
.search-area-title .active {
  border-bottom: solid 1px #0cd3fc;
}
.search-area-content {
  height: 75px;
  /* border: solid 1px red; */
}
.search-area-radio {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.search-form-item {
  margin: 5px 0;
}
.popupShow {
  position: fixed;
  top: 150px;
  right: 150px;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
}
.map-data-stat {
  width: 191px;
  height: 414px;
  background: #ffffff;
  box-shadow: 0px 4px 30px 0px rgba(56, 128, 252, 0.2);
  border-radius: 6px;
  padding: 20px;
}
.map-data-stat i {
  font-style: inherit;
}
.map-data-drop {
  width: 6px;
  height: 6px;
  background: #515e6b;
  border-radius: 3px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}
.map-data-son {
  height: 42px;
  font: index 20px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.map-data-son img {
  width: 22px;
  height: 28px;
  margin-left: 5px;
}
.map-data-top {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  display: flex;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.map-data-name {
  position: absolute;
  left: 60px;
}
</style>