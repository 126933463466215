<template>
<div class="info-container">
  <div class="info-header">
    <div class="info-header-title">{{showLang('com.data.statistics')}}</div>
    <img :src="!isShowAnalysis?`${img.light.road.single.header.down}`:`${img.light.road.single.header.upward}`" alt="" @click="showAnalysisBut">
  </div>
  <div v-if="isShowAnalysis" class="info-body">
    <div class="info-body-count">
      <AnalysisPowerTable class="count-table" />
      <AnalysisLeakageTable class="count-table" />
    </div>
    <div class="info-line"></div>
    <div class="info-body-analyse">
      <DataAnalysis />
    </div>
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import AnalysisLeakageTable from '../analysis/Leakage'
import AnalysisPowerTable from '../analysis/PowerDevice'
import DataAnalysis from '../analysis/DataAnalysis'
import { setCookie, getCookie } from '@/plugins/cookie'
export default {
  name: 'BaseGroupInfo',
  components: {
    AnalysisLeakageTable,
    AnalysisPowerTable,
    DataAnalysis,
  },
  props: {
  },
  data() {
    return {
      isShowAnalysis: true,
    }
  },
  computed: {
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState('common', ['weeks', 'productCodes', 'deviceTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    selectedNode() {
    },
    cmdResultRefresh() {
    },
  },
  mounted: function () {
    if (getCookie('isShowAnalysis') == '' || getCookie('isShowAnalysis') == 'true') {
      this.isShowAnalysis = true;
    } else {
      this.isShowAnalysis = false;
    }
  },
  destroyed: function () {
  },
  methods: {
    showAnalysisBut: function(){
      this.isShowAnalysis = !this.isShowAnalysis;
      setCookie('isShowAnalysis', this.isShowAnalysis);
    },
  }
}
</script>
<style scoped>
.info-container{
  flex: none;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.info-header{
  border-bottom: solid 1px #DEE5ED;
  width: 100%;
  height: 38px;
  flex: none;
  display: flex;
  align-items: center;
}
.info-header-title{
  width: 300px;
  flex: auto;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
}
.info-line{
  /* border: solid 1px red; */
  width: 100%;
  height: 6px;
  flex: none;
  background-color: #E3E9EF;
}
.info-body{
  height: 600px;
  flex: none;
  display: flex;
  flex-direction: column;
}
.info-body-count{
  /* border: solid 1px red; */
  flex: none;
  display: flex;
  margin: 40px 0;
}
.count-table{
  width: 50%;
  flex: auto;
  margin: 0 10px;
}
</style>