<template>
  <div class="lamp-module">
    <div class="lamp-module-tree">
      <GroupTree />
    </div>
    <div class="lamp-module-box">
      <boxPage class="station-content" v-if="selectedNode.isChanghe" />
      <groupPage class="station-content" />
    </div>
  </div>
</template>
<script>

import { mapState, mapGetters } from 'vuex'
import GroupTree from '../../common/treeGroup/Index.vue'
import boxPage from './boxPage.vue'
import groupPage from './groupPage.vue'
export default {
  name: 'module',
  components: {
    GroupTree,
    boxPage,
    groupPage,
  },
  data() {
    return {
      isShwoType: ''//
    }
  },
  created: function () {
  },
  computed: {
    ...mapState('group', ['selectedNode', 'groupLoaded', 'selectedDevices', 'groupTreeSelectedNode']),
    ...mapState('common', ['alarmTypes', 'productCodes']),
    ...mapState('auth', ['showStationMenu']),
    ...mapGetters('auth', ['showLang']),
  },
  watch: {
    groupTreeSelectedNode() {
      console.log('this.groupTreeSelectedNode----')
      console.log(this.groupTreeSelectedNode)
    }
  },
  mounted: function () {
    console.log(this.selectedNode)
    console.log(this.groupTreeSelectedNode)
  },
  destroyed: function () {
  },
  methods: {
    init() {

    },
  },
}
</script>

<style scoped>
.lamp-module {
  width: 100%;
  height: 100%;
  display: flex;
}
.lamp-module-tree {
  width: 300px;
  flex: none;
  border-radius: 6px;
}
.lamp-module-box {
  margin-left: 10px;
  border: solid 1px #dcdee2;
  width: 300px;
  flex: auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background: #fff;
}
</style>