<template>
<div class="station-outputs">
  <div class="output-header">
    <div class="header-text">K{{output.content.oc}} {{output.name}} - {{output.timeContent}}</div>
    <div class="header-status header-text">
      {{outputState}}
      <img style="height:30px" :src="(output.lastData && output.lastData.ov == 1) ? img.light.road.single.viewer.contactOpen : img.light.road.single.viewer.contactClose" />
      <!-- <div class="header-button tc" @click="showHistoryData(output)">查看历史数据</div> -->
    </div>
  </div>
  <PowerContactTable  v-for="(citem, cidx) in myContacts" :contact="citem" :key="cidx" :branchs="branchs" />
</div>
</template>
<script>
import { mapGetters,mapState } from 'vuex'
import PowerContactTable from './PowerContactTable'
export default {
  name: 'PowerOutputTable',
  components:{
    PowerContactTable,
    // PowerBranchTable,
  },
  props: {
    output: {
      type: Object,
      default(){
        return {};
      }
    },
    contacts: {
      type: Array,
      default(){
        return [];
      }
    },
    branchs: {
      type: Array,
      default(){
        return [];
      }
    },
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('cmd', ['singleCmdResult']),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes', 'powerTableActions']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    myContacts: function(){
      return this.contacts.filter(p => p.content.oid == this.output.id);
    },
    outputState: function(){
      if(!this.output.lastData)return '';
      if(!this.output.lastData.es)return '';
      if(this.output.lastData.sv == 2)return this.showLang('com.sys.automatic')
      else return this.showLang('com.sys.local')
    },
  },
  mounted: function () {
    window.eventBus.$on('paramRealDataComing', params => {
      if(params.paramId != this.output.id)return;
      this.$set(this.output, 'lastData', params.data);
      this.$set(this.output, 'extraData', params.extra);
    })
  },
  destroyed: function(){
    window.eventBus.$off('paramRealDataComing');
  },
  methods: {
    getOutputName: function(){
      let os = this.outputs.filter(it => it.id == this.contact.outputId);
      if(os.length == 0) return this.contact.name;
      return os[0].name;
    },
  }
}
</script>
<style scoped>
.station-outputs{
  /* position: absolute;
  top: 800px;
  left: 350px; */
  width: 1110px;
  /* border: solid 1px red; */
  padding: 10px;
  background: #F0F4FA;
  border-radius: 12px;
}
.output-header{
  width: 100%;
  height: 50px;
  background: linear-gradient(270deg, #3682FF, #4C9EFF);
  border-radius: 6px 6px 0px 0px;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  /* border: solid 1px red; */
}
.header-text{
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  width: 200px;
  flex: auto;
  /* border: solid 1px red; */
}
.header-status{
  width: 100px;
  flex: none;
  display: flex;
  /* border: solid 1px red; */
  align-items: center;
  justify-content: flex-end;
  /* border: solid 1px red; */
}
.header-button{
  /* position: absolute;
  top: 10px;
  right: 10px; */
  /* border: 1px solid #FFFFFF; */
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3880FC;
  width: 103px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 6px;
  cursor: pointer;
}
</style>