<template>
  <div v-if="controlCount > 0" class="main-table">
    <div class="table">
      <div class="table-title tc">
        <img :src="img.iconPowerSecurity" />{{showLang('com.lighting.security')}}
      </div>
      <div class="table-header">
        <div class="table-header-content" style="width: 172.5px;text-align: center;">{{showLang('com.tab.title')}}</div>
        <div class="table-header-content" style="width: 100px;text-align: center;">{{showLang('com.state')}}</div>
        <div class="table-header-content" style="width: 172.5px;text-align: center;">{{showLang('com.tab.title')}}</div>
        <div class="table-header-content" style="width: 100px;text-align: center;">{{showLang('com.state')}}</div>
        <div class="table-header-content" style="width: 172.5px;text-align: center;">{{showLang('com.tab.title')}}</div>
        <div class="table-header-content" style="width: 100px;text-align: center;">{{showLang('com.state')}}</div>
        <div class="table-header-content" style="width: 172.5px;text-align: center;">{{showLang('com.tab.title')}}</div>
        <div class="table-header-content" style="width: 100px;text-align: center;">{{showLang('com.state')}}</div>
      </div>
      <div class="table-content">
        <template v-for="(item, idx) in doors">
          <div class="column-header" :title="item.name" :key="`dn${idx}`"><img :src="img.statusTitleDoor" />{{item.name}}</div>
          <div :class="['column-content', 'tc', item.lastData.sv !== item.content.av ? 'status-green' : 'alarm-high']" :key="`dv${idx}`">{{item.lastData.sv !== item.content.av ? showLang('com.state.close') : showLang('com.state.turn.on')}}</div>
        </template>
        <template v-for="(item, idx) in locks">
          <div class="column-header" :title="item.name" :key="`ln${idx}`"><img :src="img.statusTitleLock" />{{item.name}}</div>
          <div :class="['column-content', 'tc', item.lastData.sv !== item.content.av ? 'status-green' : 'alarm-high']" :key="`lv${idx}`">{{item.lastData.sv !== item.content.av ? showLang('com.state.close') : showLang('com.state.turn.on')}}</div>
        </template>
        <template v-for="(item, idx) in smokes">
          <div class="column-header" :title="item.name" :key="`sn${idx}`"><img :src="img.statusTitleSmoke" />{{item.name}}</div>
          <div :class="['column-content', 'tc', item.lastData.sv !== item.content.av ? 'status-green' : 'alarm-high']" :key="`sv${idx}`">{{item.lastData.sv !== item.content.av ? showLang('com.state.normal') : showLang('com.right.nav.alarm')}}</div>
        </template>
        <template v-for="(item, idx) in waters">
          <div class="column-header" :title="item.name" :key="`wn${idx}`"><img :src="img.statusTitleWater" />{{item.name}}</div>
          <div :class="['column-content', 'tc', item.lastData.s1 !== item.content.av1 ? 'status-green' : 'alarm-high']" :key="`wv${idx}`">{{item.lastData.s1 !== item.content.av1 ? showLang('com.ins.waterless') : showLang('com.right.nav.alarm')}}</div>
        </template>
        <template v-if="switchs.length>0">
          <template v-for="(item, idx) in switchs">
            <div class="column-header" :title="item.name" :key="`swn${idx}`"><img :src="img.statusTitleSwitch" />{{item.name}}</div>
            <div :class="['column-content', 'tc', item.lastData.v == 2 ? 'status-green' : 'alarm-high']" :key="`swv${idx}`">{{texts[item.lastData.v]}}</div>
          </template>
        </template>
        <template v-if="remainCount.length>0">
          <template v-for="n in remainCount">
            <div class="column-header" :key="`nn${n}`"></div>
            <div class="column-content" :key="`nv${n}`"></div>
          </template>
        </template>

      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'PowerSecurityTable',
  components: {
  },
  props: {
    doors: {
      type: Array,
      default(){
        return [];
      }
    },
    locks: {
      type: Array,
      default(){
        return [];
      }
    },
    smokes: {
      type: Array,
      default(){
        return [];
      }
    },
    waters: {
      type: Array,
      default(){
        return [];
      }
    },
    switchs: {
      type: Array,
      default(){
        return [];
      }
    },
  },
  data() {
    return {
      texts: {
        '-9': '无',
        0: '停止',
        1: '手动',
        2: '遥控',
        3: '时控',
        4: '故障',
      },
    }
  },
  watch: {
  },
  computed: {
    ...mapState('cmd', ['singleCmdResult']),
    ...mapGetters('auth', ['showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    remainCount: function () {
      return 12 - this.controlCount;
    },
    controlCount: function () {
      let cnt = 0;
      if (this.doors) {
        cnt += this.doors.length;
      }
      if (this.locks) {
        cnt += this.locks.length;
      }
      if (this.smokes) {
        cnt += this.smokes.length;
      }
      if (this.waters) {
        cnt += this.waters.length;
      }
      if (this.switchs) {
        cnt += this.switchs.length;
      }
      return cnt;
    }
  },
  mounted: function () {
    this.texts={
        '-9': this.showLang('com.op.none') ,
        0:this.showLang('com.op.cease') ,
        1:this.showLang('com.op.hand') ,
        2:this.showLang('com.op.remote') ,
        3:this.showLang('com.op.time.control') ,
        4: this.showLang('com.op.error') ,
    }
    window.eventBus.$on('paramRealDataComing', params => {
      let ls = this.doors.filter(p => p.id == params.paramId);
      for(let l of ls){
        this.$set(l, 'lastData', params.data);
        this.$set(l, 'extraData', params.extra);
      }
      ls = this.locks.filter(p => p.id == params.paramId);
      for(let l of ls){
        this.$set(l, 'lastData', params.data);
        this.$set(l, 'extraData', params.extra);
      }
      ls = this.smokes.filter(p => p.id == params.paramId);
      for(let l of ls){
        this.$set(l, 'lastData', params.data);
        this.$set(l, 'extraData', params.extra);
      }
      ls = this.waters.filter(p => p.id == params.paramId);
      for(let l of ls){
        this.$set(l, 'lastData', params.data);
        this.$set(l, 'extraData', params.extra);
      }
      ls = this.switchs.filter(p => p.id == params.paramId);
      for(let l of ls){
        this.$set(l, 'lastData', params.data);
        this.$set(l, 'extraData', params.extra);
      }
    })
  },
  destroyed: function(){
    window.eventBus.$off('paramRealDataComing');
  },
  methods: {
    panelClick: function () {
      return false;
    }
  }
}
</script>
<style scoped>
.main-table {
  width: 1110px;
  background: #f0f4fa;
  border-radius: 12px;
  padding: 10px;
}
.table {
  /* border-collapse: collapse; */
  display: flex;
  flex-direction: column;
  border-radius: 12px;
}
.table-title {
  height: 50px;
  line-height: 50px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: linear-gradient(-90deg, #3682ff, #4c9eff);
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  position: relative;
}
.header-button {
  position: absolute;
  top: 10px;
  right: 20px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  line-height: 22px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  width: 89px;
  height: 25px;
  cursor: pointer;
}
.table-title img {
  vertical-align: middle;
  margin-right: 3px;
}
.table-header {
  width: 1110px;
  text-align: center;
  display: flex;
}
.table-content {
  width: 1110px;
  display: flex;
  flex-wrap: wrap;
}
.table-header-content {
  flex: none;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0d3a6a;
  opacity: 0.8;
  border: solid 1px white;
  text-align: left;
  height: 38px;
  line-height: 38px;
  width: 140px;
  background: #c8daef;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.column-header {
  flex: none;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* color: #FFFFFF; */
  color: #0d3a6a;
  opacity: 0.8;
  border: solid 1px white;
  text-align: left;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  width: 172.5px;
  background: #c8daef;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.column-header img {
  vertical-align: middle;
  margin-right: 10px;
}
.column-content {
  line-height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* color: #52FBFE; */
  color: #0d3a6a;
  background: #e8eff7;
  border: solid 1px white;
  height: 40px;
  width: 100px;
}
</style>