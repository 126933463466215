<template>
  <div class="contact-table">
    <div class="contact-item-line"></div>
    <div class="contact-name">{{contact.name}}</div>
    <img class="contact-img" :src="isContactImg()" :title="isContactTiele()"/>
    <PowerBranchTable class="branch-pos" v-for="(branch, bidx) in myBranchs" :key="bidx" :branch="branch" :index="bidx" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PowerBranchTable from './PowerBranchTable'
export default {
  name: 'PowerContactTable',
  components: {
    PowerBranchTable,
  },
  props: {
    contact: {
      type: Object,
      default() {
        return {};
      }
    },
    branchs: {
      type: Array,
      default() {
        return [];
      }
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    myBranchs: function () {
      let bs = this.branchs.filter(p => p.content.cid == this.contact.id);
      bs.sort((a, b) => {
        if (a.content.tt == b.content.tt) return 0;
        return a.content.tt < b.content.tt ? 1 : -1;
      })
      return bs;
    }
  },
  mounted: function () {
    window.eventBus.$on('paramRealDataComing', params => {
      if(params.paramId != this.contact.id)return;
      this.$set(this.contact, 'lastData', params.data);
      this.$set(this.contact, 'extraData', params.extra);
    })
  },
  destroyed: function(){
    window.eventBus.$off('paramRealDataComing');
  },
  methods: {
    isContactTiele() {
      if (this.contact.lastData && this.contact.lastData.sv == 1) {
        return  this.showLang('com.state.close');
      }
      if (this.contact.lastData && this.contact.lastData.sv == 0) {
        return this.showLang('com.state.break');
      }
      if (this.contact.lastData && this.contact.lastData.sv == -1) {
        return  this.showLang('com.state.not.configured');//未配置
      }
      if (this.contact.lastData && this.contact.lastData.sv == -2) {
        return  this.showLang('com.state.not.detection');//没检测到
      }
      return this.showLang('com.state.break');//断开
    },
    isContactImg() {
      if (this.contact.lastData && this.contact.lastData.sv == 1) {
        return this.img.light.road.single.viewer.contactOn
      }
      if (this.contact.lastData && this.contact.lastData.sv == 0) {
        return this.img.light.road.single.viewer.contactOff;
      }
      if (this.contact.lastData && this.contact.lastData.sv == -1) {
        return this.img.light.road.single.viewer.contactNoCheck//没配置
      }
      if (this.contact.lastData && this.contact.lastData.sv == -2) {
        return this.img.light.road.single.viewer.contactFail//没检测到
      }
      return this.img.light.road.single.viewer.contactOff;
    },
    getOutputName: function () {
      let os = this.outputs.filter(it => it.id == this.contact.outputId);
      if (os.length == 0) return this.contact.name;
      return os[0].name;
    },
  }
}
</script>
<style scoped>
.branch-pos {
  margin: 50px 0 0 220px;
}
.k-name {
  position: absolute;
  top: 38px;
  left: 100px;
  width: 40px;
  height: 40px;
  line-height: 32px;
  background: #ffffff;
  border: 4px solid #83a8d9;
  border-radius: 20px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #324252;
}
.contact-output-status {
  position: absolute;
  top: 38px;
  left: 190px;
  width: 114px;
  height: 37px;
  flex: none;
  margin: auto 0;
  z-index: 10;
}
.contact-table {
  width: 100%;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  /* border: solid 1px green; */
  position: relative;
  padding-bottom: 50px;
  background: #ffffff;
  border-radius: 0px 0px 6px 6px;
}
.contact-item-device {
  position: absolute;
  top: -70px;
  right: 30px;
  width: 92px;
  height: 101px;
  margin: auto 0;
  z-index: 10;
}
.contact-box {
  /* border: solid 1px red; */
  position: absolute;
  top: 00px;
  left: -10px;
  width: 134px;
  height: 82px;
  flex: none;
  padding-bottom: 15px;
  padding-left: 20px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  display: flex;
  align-items: center;
}
.contact-box img {
  margin: 5px;
}
.contact-name {
  position: absolute;
  top: 15px;
  left: 40px;
  width: 110px;
  height: 30px;
  text-align: center;
  /* border: solid 1px red; */
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
}
.contact-img {
  position: absolute;
  top: 45px;
  left: 40px;
  width: 110px;
  height: 120px;
  flex: none;
  z-index: 15;
}
.contact-item-line {
  position: absolute;
  top: 10px;
  left: -199px;
  height: 100px;
  width: 300px;
  /* background-color: #779AC0; */
  border-left: 4px solid #83a8d9;
  border-bottom: 4px solid #83a8d9;
  border-bottom-left-radius: 25px;
  z-index: 0;
}
</style>