<template>
  <div :id="id" style="width:100%;height: 100px" />
</template>
<script>
import * as echarts from 'echarts/core';
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  TitleComponent
} from 'echarts/components';
import {
  BarChart,
  LineChart
} from 'echarts/charts';
import {
  CanvasRenderer
} from 'echarts/renderers';

echarts.use(
  [ToolboxComponent, TooltipComponent, GridComponent, LegendComponent, BarChart, LineChart, CanvasRenderer,DataZoomComponent,TitleComponent]
);
export default {
  name: 'LineChart',
  components: {
    // BMap
    // TreeGroupInfo,
    // MapDevice,
    // AlarmWindow,
    // InfoWindow
  },
  model: {
    // prop: 'value',
    // event: 'showChanged'
  },
  props: {
    // value: {
    //   type: Boolean,
    //   default: false
    // },
    id: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default(){ return {
        title: '测试折现图'
      };}
    }
  },
  data () {
    return {
      chart: null,
      chartDom: null,
      option: null,
    }
  },
  watch: {

  },
  methods: {
    initOption: function(){
      this.option = {
        title: {
          text: this.config.title
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        backgroundColor: 'transparent',
        grid: {
          bottom: 30,
          left: 60,
          right: 50,
          backgroundColor: 'transparent',
          show: true
        },
        toolbox: {
          feature: {
            saveAsImage: {show: true}
          }
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 70,
            end: 100,
            xAxisIndex: [0]
          },
          {
            type: 'inside',
            realtime: true,
            start: 70,
            end: 100,
            xAxisIndex: [0]
          }
        ],
        legend: {
            data: [],
        },
        xAxis: [
          {
            type: 'time',
            boundaryGap: ['20%', '20%'],
          }
        ],
        yAxis: [
          {
            type: 'value',
            boundaryGap: ['20%', '20%']
          },
        ],
      };
    },
    loadData: function(series, legend, yAxis, grid){
      this.chart.setOption({series: series, legend: legend, yAxis: yAxis, grid: grid});
    },
    setXAxis: function(xAxis){
      this.chart.setOption({xAxis: xAxis});
    }
  },
  mounted: function(){
      this.chartDom = document.getElementById(this.id);
    this.chart = echarts.init(this.chartDom);
    this.initOption();
    this.chart.setOption(this.option);
  },
}
</script>
<style scoped>
.color{
  color: #cc5ec752;
}
</style>
