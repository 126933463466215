<template>
  <div :class="[showMore?'control-table hideShita':'control-table element']">
    <div class="info-header">
      <div class="info-header-title">{{showLang('com.lighting.common.operate')}}</div>
      <img :src="!showMore?`${img.light.road.single.header.down}`:`${img.light.road.single.header.upward}`" alt=""
        @click="switchMenu">
    </div>
    <div class="control-area">
      <template v-for="item in lightCmds">
        <div class="control-item" :key="item.code" @click="docmd(item)">
          <img :src="item.img" />
          {{item.name}}
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: 'LightControlMenu',
  components: {
  },
  props: {
  },
  data() {
    return {
      showMore: false,
      lightCmds: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('auth', ['config']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  mounted: function () {
    this.getLightCommands();
  },
  methods: {
    getLightCommands: function(){
      this.$axios.post(`//${this.domains.trans}/station/command/QueryLampCommonCmds`, {}).then(res => {
        if(res.code != 0)return;
        // let groups = [];
        let cmds = [];
        res.data.map(p => {
          if(this.config.lightShowCommands.includes(p.code)){
            p.img = this.img.light.road.single.lightCtrl[p.icon];
            cmds.push(p);
          }
        })
        this.$set(this, 'lightCmds', cmds);
        // console.log('get cmd data', this.lightCmds);
      });
    },
    docmd: function (item) {
      item.paramsCode = item.code;
      this.$emit('command', item);
    },
    switchMenu: function () {
      this.showMore = !this.showMore
    },
    panelClick: function () {
      return false;
    }
  }
}
</script>
<style scoped>
.info-header {
  border-bottom: solid 1px #dee5ed;
  width: 100%;
  height: 38px;
  flex: none;
  display: flex;
  align-items: center;
}
.info-header-title {
  width: 300px;
  flex: auto;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
}
.control-table {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background-color: white;
}
.control-area {
  /* border: solid 1px red; */
  flex: auto;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  align-content: flex-start;
}
.control-item {
  width: 170px;
  height: 90px;
  flex: none;
  margin: 0 35px;
  /* margin-left: 80px; */
  margin-top: 15px;
  z-index: 2;
  /* border: solid 1px red; */
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  cursor: pointer;
}
.control-item img {
  margin-right: 10px;
}
.control-output-op {
  width: 114px;
  height: 37px;
  margin: 15px auto;
  cursor: pointer;
}
.control-table.hideShita {
  height: 410px; 
  transition: height 0.5s;
}
.control-table.element {
  height: 195px;
  transition: height 0.5s;
}
</style>