<template>
  <div class="config-box">
    <PowerMainTable  :mitem="mains"   class="block-pos" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import PowerMainTable from './PowerMainTable'
// import PowerSecurityTable from './PowerSecurityTable'
// import PowerOutputTable from './PowerOutputTable'
// import PowerVoltageTable from './PowerVoltageTable'
// import PowerLeakageTable from './PowerLeakageTable'
export default {
  name: 'PowerRealData',
  components: {
    PowerMainTable,
    // PowerSecurityTable,
    // PowerOutputTable,
    // PowerLeakageTable,
    // PowerVoltageTable,
  },
  props: {
  },
  data() {
    return {
      showEditModal: false,
      showPopup: false,
      showPopupText: "",
      replaceButIndex: 1,
      editItem: {},
      commandItem: {},
      showCommandModal: false,
      deviceIds: [],
      mains: {},
      energys: [],
      switchs: [],
      outputs: [],
      contacts: [],
      branchs: [],
      doors: [],
      locks: [],
      smokes: [],
      waters: []
    }
  },
  computed: {
    ...mapState("cmd", ["cmdResultRefresh", "commands", 'windowOnResize', "singleCmdResult"]),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    leakages: function () {
      return this.branchs.filter(p => p.content.enl && !p.content.enc && !p.content.enp && !p.content.ens);
    },
    powerMainImg: function () {
      let us = this.mains.filter(p => p.content.enu);
      if (us.length == 0) return this.img.powerMainOut;
      let main = us[0];
      if (main.ua == 0 && main.ub == 0 && main.uc == 0) return this.img.powerMainOff;
      return this.img.powerMainOn;
    },
  },
  watch: {
    cmdResultRefresh() {
      let cmdDevice = this.commands.filter(p => p.name == "恢复供电" || p.name ==this.showLang('com.op.power.restoration'));//恢复供电
      if (cmdDevice.length > 0){
        switch (this.replaceButIndex) {
          case 1:
            this.showPopupText = this.showLang('com.op.manual.trip')
            break;
          case 2:
            this.showPopupText = this.showLang('com.op.clear.linkage')
            break;
          case 3:
            this.showPopupText = this.showLang('com.op.power.restoration')
            break;
        }
        switch (cmdDevice[0].status) {
          case 0:
            this.showPopupText = this.showPopupText + `${cmdDevice[0].result}`;
            break;
          case 1:
            this.showPopupText = this.showPopupText + this.showLang('com.ins.wait');
            break;
          case 2:
            this.showPopupText = this.showPopupText + this.showLang('com.ins.timeout');
            break;
          case 3:
            this.showPopupText = this.showPopupText + this.showLang('com.ins.fail')+ `：${cmdDevice[0].result}`;
            break;
          case 4:
            this.showPopupText = this.showPopupText + this.showLang('com.ins.execution')+`：${cmdDevice[0].content.pkg} / ${cmdDevice[0].content.total}`;
            break;
          case 9:
            this.showPopupText = this.showPopupText + this.showLang('com.tips.set.success')+`：${cmdDevice[0].result}`;
            break;
        }
      }
      let refreshCmds = this.commands.filter(p => (p.code == 'forceRead') && this.deviceIds.indexOf(p.deviceId) != -1 && p.status == 9);
      if(refreshCmds.length > 0){
        setTimeout(this.initData, 1000);
      }
    },
    selectedNode(){
      this.initData();
    }
  },
  mounted: function () {
    this.initData();
  },
  methods: {
    getRelateEnergy: function(m){
      let es = this.energys.filter(p => p.content.mid == m.id);
      if(es.length > 0)return es[0];
      return null;
    },
    initData: function(){
      let accountId = 0, stationId = 0;
      stationId = this.selectedNode.id;
      accountId=this.selectedNode.accountId
      this.$axios.post(`//${this.domains.trans}/station/config/QueryChangheStationState`, {accountId, stationId}).then(res => {
        if(res.code == 0){
          this.mains=res.data
          // this.$set(this, 'mains', res.data);
        }
      });
    },
    getContactBranchs: function (contactId) {
      return this.branchs.filter(item => item.contactId == contactId);
    },
    panelClick: function () {
      return false;
    },
    // showHistoryData: function (params) {
    //   console.log('show his', params);
    // },
    isShwoMains() {
      return this.mains.filter(item => item.content.mcc > 0 && item.content.bcc > 0);
    },
    replaceBut(index) {
      if (index == 2) return false;
      switch (index) {
        case 1:
          this.showPopupText = this.showLang('com.op.ok')+this.showLang('com.op.manual.trip') + "？"
          break;
        case 2:
          this.showPopupText = this.showLang('com.op.ok')+this.showLang('com.op.clear.linkage') + "？"
          break;
        case 3:
          this.showPopupText = this.showLang('com.op.ok')+this.showLang('com.op.power.restoration') + "？"
          break;
      }
      this.replaceButIndex = index;
      this.showPopup = true;
    },
    okPopup() {
      let devs = this.isShwoMains();
      let list = [];
      let arr = {
        args: {
          channel: 2,
          expire: "2099-01-01 00:00:00",
          open: true,
          point: false,
        },
        cmdId: "",
        code: "handControl",
        content: {},
        deviceId: 4562,
        id: "openControl1",
        name: "开关控制",
        paramId: 10771,
        result: "",
        status: 0,
      }
      /// 总空开通道mcc
      /// 支路供电通道bcc
      devs.forEach((el, index) => {
        switch (this.replaceButIndex) {
          case 1:
            arr.args.channel = el.content.mcc;
            arr.name = this.showLang("com.op.manual.trip");
            break;
          case 2:
            arr.name = this.showLang("com.op.clear.linkage");
            break;
          case 3:
            arr.args.channel = el.content.bcc;
            arr.name = this.showLang("com.op.power.restoration");
            break;
        }
        arr.deviceId = el.deviceId;
        arr.paramId = el.id;
        arr.id = "openControl" + index;
        list.push(arr)
      });
      switch (this.replaceButIndex) {
        case 1:
          this.$store.dispatch('cmd/sendCommands', list);
          break;
        case 2:
          this.$store.dispatch('cmd/sendCommands', list);
          break;
        case 3:
          this.$store.dispatch('cmd/sendCommands', list);
          break;
      }
      return false;
    },
    getCmdStatus: function (cmd) {
      // console.log('cmd111111', cmd)
      switch (cmd.status) {
        case 0: return `${cmd.result}`;
        case 1: return this.showLang('com.ins.wait');
        case 2: return this.showLang('com.ins.timeout');
        case 3: return this.showLang('com.ins.fail')+`：${cmd.result}`;
        case 4: return this.showLang('com.ins.execution')+`：${cmd.content.pkg} / ${cmd.content.total}`;
        case 5: return this.showLang('com.ins.fail.retry')+`：${cmd.result}`;
        case 9:
          // console.log('getCmdStatus', cmd)
      }
    },
    cancelPopup() {

    },
  }
}
</script>
<style scoped>
.block-pos {
  margin: 50px  auto;
  /* border: solid 1px red; */
}
.analyse-title {
  height: 50px;
  flex: none;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #324252;
  border-bottom: solid 1px #dee5ed;
}
.devide-line {
  border-bottom: dashed 1px #c2cdd8;
  width: 1040px;
  height: 0;
}
.main-item-line {
  position: absolute;
  top: 150px;
  left: 160px;
  bottom: 50px;
  /* height: 80%; */
  width: 350px;
  border: solid 6px #779ac0;
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
  /* border-radius: 30px; */
}
.main-item-show-channel {
  display: inline-block;
  position: relative;
  left: -61px;
  top: 300px;
}
.main-item-show-supply {
  display: inline-block;
  position: relative;
  left: -59px;
  top: 500px;
}
.main-item-but {
  width: 70px;
  height: 29px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 26px;
  display: inline-block;
  position: relative;
}
.main-item-but-orange {
  background: #ff3a39;
  top: 487px;
  left: -227px;
}
.main-item-but-green {
  background: #0ac786;
  top: 524px;
  left: -148px;
}
.main-item-but-blue {
  background: #4184f7;
  top: 334px;
  left: -155px;
}
.config-box {
  /* margin-top: 10px; */
  width: 100%;
  background-color: #ffffff;
  /* padding: 20px 64px 20px 20px; */
  /* height: 100% !important; */
  /* border: solid 1px red; */
  /* overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none; */
  position: relative;
}
.config-box::-webkit-scrollbar {
  display: none;
}
.config-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding-right: 50px;
}
.config-button-item {
  width: 104px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: linear-gradient(180deg, #0a1a3c, #0f2463);
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-right: 10px;
  cursor: pointer;
}
.config-button-item img {
  vertical-align: middle;
  margin-right: 3px;
}
.item-body {
  display: flex;
  padding-left: 20px;
  margin-right: 30px;
}
.item-body-btn {
  flex: none;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  border: solid 1px #0b1530;
  text-align: center;
  width: 90px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.item-body-btn:hover {
  border: 1px solid #52fbfe;
  border-radius: 5px;
  background: rgba(20, 13, 13, 0.2);
}
.item-body-btn img {
  width: 32px;
  height: 32px;
  margin: 10px auto;
}
.contact-list {
  display: flex;
  /* margin-bottom: 100px; */
  /* border: solid 1px blue; */
}
.contact-item {
  /* margin: auto 0; */
  margin-top: 50px;
  /* border: solid 1px blue; */
}
.branch-list {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  /* border: solid 1px red; */
}
.branch-item {
  margin-bottom: 50px;
  /* border: solid 1px red; */
}
.branch-line {
  border-top: dashed 1px #c2cdd8;
  width: 100%;
  height: 50px;
}
</style>