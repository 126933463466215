<template>
  <bm-overlay :ref="`customOverlay${sample.name}`" class="overlay" pane="labelPane" @draw="draw">
    <div class="overlay-box" :ref="`overlayBox${sample.name}`" :key="keyIndex" @click="groupInfo()">
      <span class="overlay-img"><img :src="img.pole.icons.pole.alarm10" alt=""></span>
      <span class="overlay-name">{{sample.name}}</span>
      <span class="overlay-num">{{itemNum()}}</span>
      <span class="overlay-triangle" :style="{left:offsetWidth/2-8+'px'}"></span>
    </div>
  </bm-overlay>
</template>

<script>
import { BmOverlay } from 'vue-baidu-map'
export default {
  props: ['position', 'item'], // 用来接受传入的值，用来定制样式
  components: {
    BmOverlay
  },
  watch: {
    position: {
      handler() {
        this.$refs[`customOverlay${this.sample.name}`].reload()  // 当位置发生变化时，重新渲染，内部会调用draw
      },
      deep: true
    }
  },
  data() {
    return {
      sample: '',
      offsetWidth: 80,
      keyIndex: 1,
    }
  },
  mounted() {
    this.sample = this.item;
  },
  methods: {
    groupInfo() {
      this.$emit('overLayBut', this.sample);
    },
    itemNum() {
      let data = this.sample;
      if (data == '' || data == null) return 0;
      let num = data.light.total + data.line.total + data.lux.total + data.power.total
      return num;
    },
    draw({ el, BMap, map }) {
      const { lng, lat } = this.position
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat))
      this.offsetWidth = this.$refs[`overlayBox${this.sample.name}`].offsetWidth;
      if (this.offsetWidth == 0) { this.offsetWidth = 80 }
      el.style.left = pixel.x - this.offsetWidth / 2 + 'px'
      el.style.top = pixel.y - 34 + 'px';
      this.keyIndex = Math.random() * 10000000000000;
    }
  }
}
</script>

<style>
.overlay {
  position: absolute;
  display: flex;
}
.overlay-box {
  display: flex;
  /* width: auto; */
  height: 34px;
  background: #3880fc;
  border-radius: 17px;
  line-height: 34px;
  padding-right: 10px;
  /* position: relative; */
  /* border: solid 1px red; */
}
.overlay-img {
  width: 28px;
  height: 28px;
  flex: none;
  background: #ffffff;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-left: 3px;
  margin-top: 3px;
}
.overlay-img img {
  width: 14px;
  height: 18px;
}
.overlay-line {
  flex: none;
  width: 1px;
  height: 28px;
  background: #ffffff;
  display: inline-block;
}
.overlay-name {
  white-space: nowrap;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  /* margin-left: 9px; */
  vertical-align: top;
  border-right: 1px solid #fff;
  padding: 0 15px;
}
.overlay-num {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  vertical-align: top;
  margin: 0 9px;
}
.overlay-triangle {
  width: 0;
  height: 0;
  border-top: 8px solid #3880fc;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  position: absolute;
  top: 34px;
  left: 60px;
  /* border: solid 1px red; */
}
</style>