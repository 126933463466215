<template>
  <div class="button-wide">
    <div :class="[`button-inside-${color}`, 'fix-all']">{{text}}</div>
  </div>
</template>
<script>
  export default {
    name: 'StatusButton',
    components:{
    },
    props: {
      type: {
        type: String,
        default: 'status'
      },
      color: {
        type: String,
        default: 'blue'
      },
      text:{
        type: String,
        default: '按钮'
      }
    },
    data () {
      return {
      }
    },
    methods: {
    }
  }
</script>
<style>
.button-wide{
  /* width: 100px; */
  height: 36px;
  /* background: linear-gradient(180deg, #50608B, #121E3F); */
  /* background: linear-gradient(180deg, #DFE5F5, #596586); */
  background-color: #E9F0F9;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border-radius: 6px;
  /* border-radius: 8px; */
  padding: 5px;
  text-align: center;
}
.fix-all{
  /* border: solid 1px red; */
  height: 100%;
  padding: 0 10px;
  line-height: 24px;
  white-space: nowrap;
}
.button-inside-blue{
  /* width: 58px; */
  background: linear-gradient(0deg, #3880FC, #609AFE);
  /* box-shadow: inset -2px 2px 5px 0px rgba(3, 28, 69, 0.66), inset 0px 0px 11px 5px rgba(3, 52, 143, 0.8); */
  border-radius: 6px;
  color: white;
}
.button-inside-yellow{
  background: linear-gradient(0deg, #F3D402, #FFE84E);
  /* background: linear-gradient(0deg, #958502, #FCFE72, #FCFD9E);
  box-shadow: inset -2px 2px 5px 1px rgba(72, 58, 0, 0.51),inset 0px 0px 11px 5px rgba(196, 161, 0, 0.83); */
  border-radius: 6px;
  color: #021629;
}
.button-inside-red{
  /* width: 58px; */
  background: linear-gradient(0deg, #9E1D1D, #F57272);
  box-shadow: inset 0px 0px 7px 2px rgba(65, 0, 0, 0.56);
  /* background: linear-gradient(0deg, #C51616, #F57272);
  box-shadow: inset -2px 2px 5px 0px #4A0101, inset 0px 0px 11px 5px rgba(65, 0, 0, 0.6); */
  border-radius: 6px;
  color: white;
}
.button-inside-gray{
  /* width: 58px; */
  background: linear-gradient(0deg, #cccaca, #8d8c8c);
  box-shadow: inset 0px 0px 7px 2px rgba(65, 0, 0, 0.56);
  /* background: linear-gradient(0deg, #C51616, #F57272);
  box-shadow: inset -2px 2px 5px 0px #4A0101, inset 0px 0px 11px 5px rgba(65, 0, 0, 0.6); */
  border-radius: 6px;
  color: white;
}
.button-inside-green{
  background: linear-gradient(0deg, #1E670D, #6CEA6C);
  box-shadow: 0px 0px 7px 2px rgba(10, 84, 0, 0.52);
  border-radius: 6px;
  color: white;
}
</style>