<template>
  <Modal v-model="showModal" :mask-closable="false" width="300px">
    <div slot="header">{{item.type == 'bind' ? '绑定' : '验证'}}用户U盾信息</div>
    <div class="user-edit-content">
      <Form ref="form" :model="form" :label-width="70">
        <FormItem prop="usbname" label="U盾信息">
          <Input type="text" maxlength="20" v-model="form.usbname" readonly placeholder="请插入U盾"></Input>
           <!-- -  -->
          <!-- {{form.usbkey}} - {{form.userId}} -->
        </FormItem>
        <FormItem v-if="pswd.enable" prop="password" label="U盾密码">
          <Input ref="password" type="password" maxlength="20" v-model="pswd.content" placeholder=""></Input>
        </FormItem>
        <FormItem v-if="item.type == 'bind' && pswd.enable" prop="expire" label="" :label-width="0">
          <Button type="success" long @click="bindUsbKey">确认绑定</Button>
        </FormItem>
      </Form>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
      <Button v-if="item.type == 'check' && pswd.enable" type="success" @click="checkUsbKey">验证U盾</Button>
    </div>
  </Modal>
</template>
<script>
import {mapState} from 'vuex'
export default {
  name: 'ModalUsbKeyCheck',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      pswd: {
        enable: false,
        content: '',
      },
      form: {
        usbname: '',
        usbkey: '',
        userId: 0,
      },
    }
  },
  computed: {
    ...mapState('auth', ['organs', 'customers']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(this.showModal){
        this.form.userId = this.item.userId;
        this.form.usbname = "请插入U盾";
        this.form.usbkey = "";
        this.pswd.enable = false;
        this.pswd.content = '';
        this.xtx.load(this.xtxCallback);
      }else{
        this.xtx.unload();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  methods: {
    xtxCallback: function(type, content){
      if(type == "user"){
        let users = [];
        if(content && content.length > 0){
          let cs = content.split('&&&');
          if(cs.length > 0){
            for(let c of cs){
              let us = c.split('||');
              if(us.length == 2){
                users.push({name: us[0], key: us[1]});
              }
            }
          }
        }
        // console.log('get user list:', users);
        if(users.length == 0){
          this.form.usbname = "请插入U盾";
          this.form.usbkey = '';
          this.pswd.enable = false;
          this.pswd.content = '';
          // this.wsobj.login(users[0].key, '123456')
        }else if(users.length > 1){
          this.form.usbname = "请不要插入多个U盾";
          this.form.usbkey = '';
          this.pswd.enable = false;
          this.pswd.content = '';
        }else{
          this.form.usbname = users[0].name;
          this.form.usbkey = users[0].key;
          this.pswd.enable = true;
          this.pswd.content = '';
        }
      }else if(type == "login"){
        if(content != 'true'){
          this.$Message.warning('密码错误');
          return;
        }
        if(this.item.type == 'bind'){
          this.sendUsbBind();
        }else if(this.item.type == "check"){
          this.sendUsbCheck();
        }
      }
    },
    checkUsbKey: function(){
      this.xtx.login(this.form.usbkey, this.pswd.content);
    },
    bindUsbKey: function(){
      this.xtx.login(this.form.usbkey, this.pswd.content);
    },
    sendUsbCheck: function(){
      this.$axios.post(`sys/auth/CheckUserUsbKey`, {
        id: this.form.userId,
        usbkey: this.form.usbkey,
      }).then(res => {
        if(res.code == 0){
          this.$Message.warning('U盾验证成功');
          this.$emit('checked');
          this.showModal = false;
        }
      });
    },
    sendUsbBind: function(){
      this.$axios.post(`sys/auth/BindUserUsbKey`, {
        id: this.form.userId,
        usbkey: this.form.usbkey,
      }).then(res => {
        if(res.code == 0){
          this.$Message.warning('绑定成功');
        }
      });
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.user-edit-content{
  /* display: flex; */
}
.cust-fields{
  width: 300px;
  flex: auto;
}
  .form{
    height: calc(100%);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>