<template>
  <div class="box-header" :style="{backgroundImage: `url(${img.light.road.single.header.bg})`}">
    <!-- <div class="header-time"><img :src="img.light.road.single.header.clock">{{currentStation.refreshTime}}</div> -->
    <!-- <div class="header-wifi"><img :src="img.light.road.single.header.wifi">流量</div> -->
    <div class="header-title">
      {{showLang('com.group.name')}}：{{selectedNode.name}}
      <div class="header-btns">
        <Button v-if="showStationMenu != 'base'" type="default" @click="returnBase">{{showLang('com.op.return')}}</Button>
      </div>
    </div>
    <div class="header-info">
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'GroupHeader',
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('auth', ['showStationMenu']),
    currentGroup: function () {
      if (this.selectedNode.type == this.productCodes.group) {
        let ps = this.stations.filter(p => p.station.id == this.selectedNode.id);
        if (ps.length > 0) return ps[0];
      }
      return null;
    },
    powerBoxRoad: function () {
      if (!this.currentStation) return '';
      return this.groupPathName(this.currentStation.station.groupId);
    },
  },
  watch: {
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    returnBase: function () {
      this.$store.commit('auth/setStationMenu', 'base');
    },
  }
}
</script>
<style scoped>
.header-btns {
  float: right;
  display: flex;
  padding-right: 40px;
  /* align-content: space-between; */
}
.box-header {
  flex: none;
  height: 65px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  margin-top: 10px;
}
.header-time {
  position: absolute;
  left: 30px;
  top: 12px;
  width: 360px;
  height: 26px;
  background: #ffffff;
  border-radius: 13px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3880fc;
  line-height: 26px;
}
.header-time img {
  vertical-align: middle;
  margin: -3px 3px 0 10px;
}
.header-wifi {
  position: absolute;
  right: 30px;
  top: 10px;
  /* width: 360px; */
  height: 26px;
  /* background: #FFFFFF; */
  border-radius: 13px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
}
.header-wifi img {
  vertical-align: middle;
  margin: -3px 5px 0 10px;
}
.header-title {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  margin: 10px 0 15px 0;
}
.header-info {
  width: 1469px;
  height: 32px;
  line-height: 32px;
  margin: 0 auto;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff99;
  display: flex;
}
.header-info-item {
  flex: auto;
  text-align: center;
}
.header-info-item img {
  /* margin-top: 10px; */
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
  margin-top: -3px;
}
</style>