import { render, staticRenderFns } from "./PowerRealData.vue?vue&type=template&id=0a51e084&scoped=true"
import script from "./PowerRealData.vue?vue&type=script&lang=js"
export * from "./PowerRealData.vue?vue&type=script&lang=js"
import style0 from "./PowerRealData.vue?vue&type=style&index=0&id=0a51e084&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a51e084",
  null
  
)

export default component.exports