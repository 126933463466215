<template>
  <div class="station-search-container">
    <div class="con-info">
      <div class="info-title">{{showLang('cdm.data.basic.info')}}</div>
      <div class="info-details">
        <!-- <div class="info-list"> <img :src="img.light.info.assetsYearNum" alt=""><span class="info-name"> 配电柜编码:</span><span class="info-con">21120119</span> </div> -->
        <div class="info-list"> <img :src="img.light.info.powerBox" alt=""><span class="info-name"> {{showLang('com.device.box')}}: </span><span
            class="info-con">{{basicData.name}} </span></div>
        <div class="info-list"> <img :src="img.light.info.unit" alt=""><span class="info-name"> {{showLang('com.site.info.manage')}}: </span><span
            class="info-con">{{basicData.owner.name}}</span></div>
        <div class="info-list"> <img :src="img.light.info.user" alt=""><span class="info-name"> {{showLang('work.contacts')}}:</span> <span
            class="info-con">{{basicData.owner.user}} </span></div>
        <div class="info-list"> <img :src="img.light.info.number" alt=""><span class="info-name"> {{showLang('com.data.phone.num')}}:</span><span
            class="info-con">{{basicData.owner.mobile}}</span> </div>
        <div class="info-list"> <img :src="img.light.info.group" alt=""><span class="info-name"> {{showLang('com.lighting.ggd.route')}}: </span><span
            class="info-con">{{basicData.group}} </span></div>
        <div class="info-list"> <img :src="img.light.info.unit" alt=""><span class="info-name"> {{showLang('com.site.info.servic')}}: </span><span
            class="info-con">{{basicData.maintenance.name}}</span></div>
        <div class="info-list"> <img :src="img.light.info.user" alt=""><span class="info-name"> {{showLang('work.contacts')}}:</span> <span
            class="info-con">{{basicData.maintenance.user}} </span></div>
        <div class="info-list"> <img :src="img.light.info.number" alt=""><span class="info-name"> {{showLang('com.data.phone.num')}}:</span><span
            class="info-con">{{basicData.maintenance.mobile}}</span> </div>
      </div>
    </div>
    <div class="con-info">
      <div class="info-title">{{showLang('property.info')}}</div>
      <div class="info-details">
        <div class="info-list"> <img :src="img.light.info.powerBoxNum" alt=""><span class="info-name"> {{showLang('com.lighting.ggd.num')}}:</span><span
            class="info-con">{{assetsData.powerbox}}</span> </div>
        <div class="info-list"> <img :src="img.light.info.lampNum" alt=""><span class="info-name"> {{showLang('com.import.cn.lamp.num')}}:</span><span
            class="info-con">{{assetsData.light}}</span></div>
        <div class="info-list"> <img :src="img.light.info.rdLampNum" alt=""><span class="info-name"> {{showLang('com.device.pole.number')}}:</span><span
            class="info-con">{{assetsData.pole}}</span> </div>
        <div class="info-list"> <img :src="img.light.info.rdNum" alt=""><span class="info-name"> {{showLang('com.device.branch.num')}}:</span> <span
            class="info-con">{{assetsData.branch}} </span></div>
        <div class="info-list"> <img :src="img.light.info.cableNum" alt=""><span class="info-name"> {{showLang('com.line.length')}}:</span><span
            class="info-con">{{assetsData.length}}</span> </div>
        <div class="info-list"> <img :src="img.light.info.cableTypeNum" alt=""><span class="info-name"> {{showLang('com.line.type')}}:</span><span
            class="info-con">{{assetsData.linetype}}</span> </div>
        <div class="info-list"> <img :src="img.light.info.assetsMonthNum" alt=""><span class="info-name"> {{showLang('property.month.change.record')}}:</span><span
            class="info-con">{{assetsData.monthChange}}</span></div>
        <div class="info-list"> <img :src="img.light.info.assetsYearNum" alt=""><span class="info-name"> {{showLang('property.year.change.record')}}:</span><span
            class="info-con">{{assetsData.yearChange}}</span> </div>
      </div>
    </div>
    <div class="con-info">
      <div class="info-title">
        <div class="chartNavTab">
          <a :class="chartNav==0?'active':''" @click="chartNav=0">{{showLang('com.op.picture')}}</a>
          <a :class="chartNav==1?'active':''" @click="chartNav=1">{{showLang('com.gis.location')}}</a>
        </div>
        <div v-if="chartNav==0">
          <div class="info-details">
            <div class="info-img-list" v-for="(el,index) in imgListData" :key="index">
              <img :src="el.poleId > 0 ? img.pole.asset.level1.total : img.light.road.single.ctrl.writeClock" :title="el.poleId > 0 ? el.poleName : '关联到灯杆'" class="info-delImg" style="top: 8px;right: 183px;" @click="setPole(el)" />
              <img :src="img.setTime" class="info-delImg" style="margin-right: 83px; background-color: #1c1b1bb5;border-radius: 3px;"
                @click="downImg(el.url)" />
              <img :src="img.light.info.enlargeImg" alt="" class="info-delImg" style="margin-right: 43px;" @click="enlarge(el)" />
              <img :src="img.light.info.deleteImg" alt="" class="info-delImg" @click="delImg(el.id)" />
              <img :src="el.url" alt="" class="info-img" />
            </div>
            <Upload ref="upload" :headers="headers" :data="headers" :show-upload-list="false" :on-success="handleSuccess" :format="['jpg','jpeg','png']"
              :max-size="20480" :on-format-error="handleFormatError" :on-exceeded-size="handleMaxSize" multiple type="drag"
              :action="`//${this.domains.trans}/station/config/UploadStationImage`" style="border:none">
              <div class="info-img-list">
                <img :src="img.light.info.addImg" alt="" class="info-img" style="cursor: pointer;">
              </div>
            </Upload>
          </div>
        </div>
        <div v-if="chartNav==1">
          <div style="height:800px">
            <lampMap :gainDeviceType="deviceArray" />
          </div>
        </div>
      </div>
    </div>
    <div class="con-info" v-if="false">
      <div class="info-title">{{showLang('com.state.service.record')}} <span class="repair-export" @click="exportData()">{{showLang('com.op.export')}}</span> </div>
      <div class="info-details">
        <u-table ref="multipleTable" :data="repairData" stripe tooltip-effect="dark" selectTrClass="selectTr" style="width: 100%">
          <u-table-column prop="name" :label="showLang('com.fault.name')">
          </u-table-column>
          <u-table-column :label="showLang('com.fault.time')">
            <template slot-scope="scope">{{ scope.row.time}}</template>
          </u-table-column>
          <u-table-column prop="user" :label="showLang('com.fault.assigned')">
          </u-table-column>
          <u-table-column :label="showLang('com.fault.type')">
            <template slot-scope="scope"> <span :class="`statusColor${scope.row.status}`"></span> {{ scope.row.statusName}}</template>
          </u-table-column>
        </u-table>
      </div>
    </div>
    <Modal v-model="isShowImg" :title="showLang('com.img.amplify')" width="1000">
      <div style="text-align: center;overflow:auto;"> <img :src="isShowImgUrl" alt=""
          :style="{width:'100%', verticalAlign: 'middle', transform: `rotate(${rotateAngle}deg)`}"></div>
      <div slot="footer">
        <Button type="info" style="margin-right: 8px" @click="leftRotate">{{showLang('com.img.Left.Rotation')}}</Button>
        <Button type="info" style="margin-right: 8px" @click="rightRotate">{{showLang('com.img.Right.Rotation')}}</Button>
        <Button style="margin-right: 8px" type="primary" @click="downImg(isShowImgUrl)">{{showLang('com.op.download')}}</Button>
        <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      </div>
    </Modal>
    <ModalPoleImage v-model="showPoleImageModal" :item="editItem" @selected="poleSelected" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import exportXlsx from '@/components/xlsx/exportXlsx'
import lampMap from '@/views/light/road/gis/mapNew/Index'
import ModalPoleImage from './ModalPoleImage'
export default {
  name: 'StationInfoIndex',
  components: {
    lampMap,
    ModalPoleImage,
  },
  props: {
  },
  data() {
    return {
      showPoleImageModal: false,
      editItem: {},
      rotateAngle: 0,
      basicData: {
        "name": "",
        "group": "",
        "owner": {
          "name": "",
          "user": "",
          "mobile": ""
        },
        "maintenance": {
          "name": "",
          "user": "",
          "mobile": ""
        }
      },
      isShowImg: false,
      isShowImgUrl: "",
      assetsData: {},
      repairData: [],
      imgListData: [],
      headers: {
        stationId: "",
        auth: localStorage.getItem('token'),
      },
      deviceArray: [
        { name: "全部", type: "all", lang: 'com.state.all' },
        { name: "配电柜", type: "powerbox", lang: 'com.device.box' },
        { name: "单灯", type: "light", lang: 'com.device.lamp' },
        { name: "电缆", type: "cable", lang: 'com.device.cable' },
      ],
      chartNav: 0,
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['weeks', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('auth', ['token']),

  },
  watch: {
    selectedNode() {
      this.allAjax();
      this.headers.stationId = this.selectedNode.id;
      // this.getImages();
    }
  },
  mounted: function () {
    this.allAjax()
    this.headers.stationId = this.selectedNode.id;
  },
  destroyed: function () {
  },
  methods: {
    poleSelected: function(params){
      // console.log('set img pole result', params)
      this.$axios.post(`//${this.domains.trans}/station/config/SetImageToPole`, {imageId:params.id, poleId: params.poleId}).then(res => {
        if (res.code !== 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.showPoleImageModal = false;
        this.$Message.info('关联成功');
        let items = this.imgListData.filter(p => p.id == params.id);
        if(items.length == 0)return;
        items[0].poleId = params.poleId;
        items[0].poleName = params.poleName;
      })
    },
    setPole: function(params){
      this.editItem = params;
      this.showPoleImageModal = true;
    },
    leftRotate: function () {
      this.rotateAngle -= 90;
    },
    rightRotate: function () {
      this.rotateAngle += 90;
    },
    allAjax() {
      this.basicAjax();
      this.assetsAjax();
      this.repairAjax();
      this.imgListAjax();
    },
    downImg(url) {//下载
      this.$axios.get(url, { responseType: "blob" }).then(response => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(response);
        link.download =this.showLang('com.img.device')+ '.png';
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
    },
    enlarge(el) {//放大
      // console.log(el)
      this.isShowImg = true;
      this.isShowImgUrl = el.url;
    },
    ok() {
    },
    cancel() {
      this.isShowImg = false;
    },
    exportData() {
      let dataArr = JSON.parse(JSON.stringify(this.repairData));
      let tabHead = {
        name:this.showLang('com.fault.name'),
        time:this.showLang('com.fault.time'),
        user:this.showLang('com.fault.assigned'),
        statusName:this.showLang('com.fault.type'),
      }
      if (dataArr.length <= 0) {
        return false;
      }
      exportXlsx(dataArr, tabHead, this.showLang('com.data.export'));
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: this.showLang('com.img.tisp.size'),
        desc:this.showLang('com.img.tisp.file.size',file.name) +'20M'
      });
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: this.showLang('com.img.tisp.format'),
        desc: this.showLang('com.img.tisp.file.format',file.name)
      });
    },
    handleSuccess(res) {
      if (res.code == 0) {
        this.imgListAjax()
      }
    },
    imgListAjax: async function () {
      let res = await this.$axios.post(`//${this.domains.trans}/station/config/QueryStationImages`, {stationId:this.selectedNode.id});
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return;
      }
      this.imgListData = [];
      for (let i = 0; i < res.data.length; i++) {
        let img = {
          url: `//${this.domains.trans}/station/config/ViewImage?id=${res.data[i].imageId}&auth=${this.token}`,
          id: res.data[i].imageId,
          poleId: res.data[i].poleId,
          poleName: res.data[i].poleName,
        }
        this.imgListData.push(img)
      }
    },
    repairAjax: async function () {
      if(this.selectedNode.type != this.productCodes.station || this.selectedNode.id <= 0)return;
      let res = await this.$axios.post(`//${this.domains.trans}/station/info/QueryRepair`, {id:this.selectedNode.id});
      if (res.code !== 0) {
        // this.$Message.error(res.data);
        return;
      }
      this.repairData = res.data;
      //1、待维修；2、维修中；3、已完成
      this.repairData.forEach(el => {
        switch (el.status) {
          case 1:
            el.statusName = this.showLang('com.state.service.wait')
            break;
          case 2:
            el.statusName = this.showLang('com.state.service.under')
            break;
          case 3:
            el.statusName = this.showLang('com.op.completed')
            break;
        }
      });
    },
    assetsAjax: async function () {
      if(this.selectedNode.type != this.productCodes.station || this.selectedNode.id <= 0)return;
      let res = await this.$axios.post(`//${this.domains.trans}/station/info/QueryAssets`, {id: this.selectedNode.id});
      if (res.code == 0) {
        this.assetsData = res.data;
      }
    },
    basicAjax: async function () {
      if(this.selectedNode.type != this.productCodes.station || this.selectedNode.id <= 0)return;
      let res = await this.$axios.post(`//${this.domains.trans}/station/info/QueryBase`, {id:this.selectedNode.id});
      if (res.code == 0) {
        this.basicData = res.data;
      }
    },
    delImg: async function (id) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content:this.showLang('com.tips.ok.del.picture'),
        onOk: async () => {
          let res = await this.$axios.delete(`//${this.domains.trans}/station/file/${id}`);
          if (res.code == 0) {
            this.$Message.info(this.showLang('com.tips.del.success'));
            this.imgListAjax();
          }
        }
      });
    },
  }
}
</script>
<style scoped>
.box-body {
  height: 0;
  flex: auto;
  margin-top: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.station-search-container {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  background-color: white;
}
.con-info {
  padding: 24px 30px 0;
}
.con-info::after {
  content: "";
  display: block;
  margin: -5px auto 0;
  border-bottom: 6px solid #e3e9ef;
}
.con-info:last-child::after {
  border-bottom: none;
}
.info-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
}
.repair-export {
  width: 50px;
  height: 28px;
  background: #3880fc;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  display: block;
  line-height: 28px;
  float: right;
  cursor: pointer;
}
.statusColor1 {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #acb5be;
  border-radius: 50%;
}
.statusColor2 {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #ffcb37;
  border-radius: 50%;
}
.statusColor3 {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #1adead;
  border-radius: 50%;
}
.info-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0 25px;
}
.info-list {
  width: 25%;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737d;
}
.info-list img {
  vertical-align: middle;
  margin-right: 10px;
}
.info-list .info-name {
  display: inline-block;
  margin-right: 5px;
}
.info-img-list {
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  background: #e3e9ef;
  border-radius: 12px;
  margin-right: 20px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 8px;
}
.info-img {
  max-width: 220px;
  max-height: 220px;
  vertical-align: middle;
}
.info-delImg {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
.chartNavTab {
  height: 48px;
  flex: none;
  display: flex;
}
.chartNavTab a.active {
  color: #021629;
  font-weight: bold;
}
.chartNavTab a.active::after {
  content: "";
  width: calc(100%);
  height: 1px;
  display: block;
  margin: -5px auto 0;
  border-bottom: 2px solid #3880fc;
}
.chartNavTab a {
  padding: 2px 10px;
  cursor: pointer;
  background-color: #fff;
  color: #324252;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 48px;
}
.info-details >>> .plTableBox .el-table th {
  background-color: #eef6ff !important;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #324252;
}
.info-details >>> .ivu-upload-drag {
  border: none;
}
.info-title >>> .ivu-tabs-tab {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
}
</style>