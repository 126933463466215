<template>
  <div class="analyse-box">
    <div class="analyse-box-title">{{ showLang('com.lighting.monitoring') }}</div>
    <div class="analyse-table">
      <table border="0" cellspacing="0" cellpadding="0">
        <tr>
          <td></td>
          <td>{{ showLang('com.num') }}</td>
          <td>{{ showLang('com.state.onLine') }}</td>
          <td>{{ showLang('com.state.police') }}</td>
          <td>{{ showLang('com.state.lighting') }}</td>
        </tr>
        <tr v-if="analyse.powerbox.total > 0">
          <td>{{ showLang('com.device.box') }}</td>
          <td>{{ analyse.powerbox.total }}</td>
          <td class="color1" @click="lookDetail(true, (flag = 'online'))">{{ analyse.powerbox.online }}</td>
          <td class="color2" @click="lookDetail(true, (flag = 'alarm'))">{{ analyse.powerbox.alarm }}</td>
          <td class="color3" @click="lookDetail(true, (flag = 'running'))">{{ analyse.powerbox.running }}</td>
        </tr>
        <!-- <tr v-if="analyse.pole.total > 0">
          <td>{{ showLang('com.device.pole') }}</td>
          <td>{{ analyse.pole.total }}</td>
          <td class="color1">{{ analyse.pole.online }}</td>
          <td class="color2">{{ analyse.pole.alarm }}</td>
          <td class="color3">{{ analyse.pole.running }}</td>
        </tr> -->
        <tr v-if="analyse.light.total > 0">
          <td>{{ showLang('com.device.lamp') }}</td>
          <td>{{ analyse.light.total }}</td>
          <td class="color1" @click="lookDetail(false, (flag = 'online'))">{{ analyse.light.online }}</td>
          <td class="color2" @click="lookDetail(false, (flag = 'alarm'))">{{ analyse.light.alarm }}</td>
          <td class="color3" @click="lookDetail(false, (flag = 'running'))">{{ analyse.light.running }}</td>
        </tr>
        <tr v-if="analyse.lamp.total > 0">
          <td>{{ showLang('com.device.light') }}</td>
          <td>{{ analyse.lamp.total }}</td>
          <td class="color1">{{ analyse.lamp.online }}</td>
          <td class="color2">{{ analyse.lamp.alarm }}</td>
          <td class="color3">{{ analyse.lamp.running }}</td>
        </tr>
      </table>
      <Spin size="large" fix v-if="loading"></Spin>
    </div>
    <Modal v-model="lookDetailShow" :title="showLang('com.data.detail')" width="800px"   :ok-text="showLang('com.op.ok')" :cancel-text="showLang('com.op.cancel')">
      <vxe-table ref="chkTable" border show-overflow show-header-overflow :data="dataShow" :height="300" :key="dataShowIndex">
        <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')"></vxe-column>
        <vxe-column field="name" :title="showLang('com.tab.title')" header-align="center">
          <template #default="{ row }">
            {{ row.name }}
          </template>
        </vxe-column>
        <!-- <vxe-column field="code" title="ID" header-align="center"></vxe-column> -->
        <vxe-column width="160" fixed="right">
          <template #default="{ row }">
            <Button @click="locateJump(row)">{{ showLang('com.data.detail') }} </Button>
          </template>
        </vxe-column>
      </vxe-table>
      <vxe-pager :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']" :current-page.sync="index" :page-size.sync="size" :total="total" @page-change="handlePageChange"> </vxe-pager>
    </Modal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'AnalysisPowerTable',
  components: {},
  props: {
    isBox: {
      type: String,
      default: 'box'
    }
  },
  data() {
    return {
      lookDetailShow: false,
      loading: false,
      dataShow: [],
      dataShowIndex: 1,
      total: 0,
      dataShowType: '',
      size: 20,
      index: 1,
      flag: 'online',
      analyse: {
        powerbox: {
          total: 0,
          online: 0,
          alarm: 0,
          running: 0
        },
        light: {
          total: 0,
          online: 0,
          alarm: 0,
          running: 0
        },
        lamp: {
          total: 0,
          online: 0,
          alarm: 0,
          running: 0
        },
      }
    }
  },
  computed: {
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedNode']),
    ...mapGetters('auth', ['showLang'])
  },
  watch: {
    selectedNode() {
      this.initData()
    }
  },
  mounted: function() {
    this.initData()
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.index = currentPage
      this.size = pageSize
      this.lookDetail(this.dataShowType == 'powerbox')
    },
    locateJump(row) {
      console.log(row)
      let nodeid = '',
        type = ''
      if (this.dataShowType == 'powerbox') {
        nodeid = row.id
        type = 'Station'
      } else {
        nodeid = row.stationId
        type ='Station'
      }
      this.$store.commit('group/selectNodeById', { nodeId: nodeid, type: type })
    },
    lookDetail(is) {
      let groupId = 0,
        stationId = 0
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id
      } else {
        groupId = this.selectedNode.id
      }
      let urlAjax = ''
      if (is) {
        this.dataShowType = 'powerbox'
        urlAjax = `//${this.domains.trans}/station/search/QueryPowerByState`
      } else {
        this.dataShowType = 'light'
        urlAjax = `//${this.domains.trans}/station/search/QueryLightByState`
      }
      this.$axios.post(urlAjax, { groupId, stationId, flag: this.flag, size: this.size, index: this.index }).then(res => {
        this.lookDetailShow = true
        this.dataShowIndex++
        if (res.code == 0) {
          this.$set(this, 'dataShow', res.data.list)
          this.$set(this, 'total', res.data.count)
        }
      })
    },
    initData: function() {
      let groupId = 0,
        stationId = 0
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id
      } else {
        groupId = this.selectedNode.id
      }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/analyse/DeviceTotal`, { groupId, stationId }).then(res => {
        //  that = this;
        this.loading = false;
        // setTimeout(() => {
        //   this.loading = false;
        // }, 1000);
        if (res.code == 0) {
          this.$set(this, 'analyse', res.data)
        }
      })
    }
  }
}
</script>
<style scoped>
.analyse-chart {
  display: flex;
  /* height: 320px; */
  margin-bottom: 30px;
}
.analyse-chart.hideShita {
  max-height: 320px;
  overflow: hidden;
  transition: max-height 0.5s;
}
.analyse-chart.element {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
  margin-bottom: -1px;
}
.data-analyse-box {
  /* width: calc(100% - 20px); */
  height: 100%;
  background-color: #fff;
  padding: 30px 64px 30px 30px;
  margin: 16px 0;
}
.analyse-title {
  /* font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
  padding-bottom: 15px;
  border-bottom: 1px solid #dee5ed;
  justify-content: space-between; */
  height: 52px;
  flex: none;
  border-bottom: solid 1px #dee5ed;
  display: flex;
  justify-content: space-between;
}
.analyse-title .title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
}
.analyse-title img.rotateImg {
  transform: rotate(180deg);
  transition: transform 0.5s;
}
.analyse-title img.rotateImg2 {
  transform: rotate(0deg);
  transition: transform 0.5s;
}
.analyse-grouping {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}
.analyse-box {
  width: 47%;
  border: 10px solid #f0f4fa;
  border-radius: 12px;
  height: 187px;
  flex: auto;
  /* position: relative; */
}
.analyse-box2 {
  border: 10px solid #f0f4fa;
  border-radius: 12px;
  height: 163px;
  width: 98%;
  margin-top: 20px;
}
.analyse-box2 .analyse-list-con {
  justify-content: center;
}
.analyse-box-title {
  width: 100%;
  height: 50px;
  background: linear-gradient(270deg, #3682ff, #4c9eff);
  border-radius: 6px 6px 0px 0px;
  text-align: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #feffff;
  line-height: 50px;
}
.analyse-list {
  display: flex;
  justify-content: center;
}

.analyse-list-con {
  width: 25%;
  height: 93px;
  box-sizing: border-box;
  padding-left: 22px;
  border-right: 1px solid #d0d9e9;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.analyse-list-con span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.analyse-list-con span i {
  font-size: 16px;
  font-family: Bahnschrift;
  font-weight: 400;
  font-style: inherit;
  color: #324252;
}
.analyse-list-con:last-child {
  border-right: none;
}
.analyse-table{
  position: relative;
}
.analyse-table table {
  /* border: solid 1px red; */
  width: 100%;
  height: 117px;
}
.analyse-table table td {
  width: 115px;
  text-align: center;
  box-sizing: border-box;
  border-right: 1px solid #d0d9e9;
  border-bottom: 1px solid #d0d9e9;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.analyse-table table tr:last-child td {
  border-bottom: none;
}
.analyse-table table tr td:last-child {
  border-right: none;
}
.analyse-single {
  width: 100%;
}
.analyse-single-list {
  display: flex;
  justify-content: center;
}
.analyse-table table td.color1 {
  color: #2bd58c;cursor:pointer; 
}
.analyse-table table td.color2 {
  color: #fec71e;cursor:pointer; 
}
.analyse-table table td.color3 {
  color: #ff5d4c;cursor:pointer; 
}
.analyse-table table td.color4 {
  color: #b153e7;
}
</style>
