<template>
<div class="analyse-box">
  <div class="analyse-box-title">{{showLang('com.lighting.leakage')}}</div>
  <div class="analyse-table">
    <table border="0" cellspacing="0" cellpadding="0">
      <tr>
        <td></td>
        <td>{{showLang('com.lighting.num.lines')}}</td>
        <td>{{showLang('com.lighting.normal.Line')}}</td>
        <td>{{showLang('com.lighting.minor.leakage')}}</td>
        <td>{{showLang('com.lighting.severe.leakage')}}</td>
        <td>{{showLang('com.lighting.trip.circuit')}}</td>
      </tr>
      <tr>
        <td>{{showLang('com.num')}}</td>
        <td>{{analyse.total}}</td>
        <td class="color1">{{analyse.ok}}</td>
        <td class="color2">{{analyse.ll}}</td>
        <td class="color3">{{analyse.lh}}</td>
        <td class="color4">{{analyse.lx}}</td>
      </tr>
      <tr>
        <td>{{showLang('com.proportion')}}</td>
        <td>--</td>
        <td class="color1">{{analyse.total == 0 ? 0 : img.val(analyse.ok * 100 / analyse.total, 2)}}%</td>
        <td class="color2">{{analyse.total == 0 ? 0 : img.val(analyse.ll * 100 / analyse.total, 2)}}%</td>
        <td class="color3">{{analyse.total == 0 ? 0 : img.val(analyse.lh * 100 / analyse.total, 2)}}%</td>
        <td class="color4">{{analyse.total == 0 ? 0 : img.val(analyse.lx * 100 / analyse.total, 2)}}%</td>
      </tr>
    </table>
    <Spin size="large" fix v-if="loading"></Spin>
  </div>
</div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'AnalysisLeakageTable',
  components: {
  },
  directives: {
  },
  props: {
  },
  data() {
    return {
      loading: false,
      analyse: {
        total: 0,
        ok: 0,
        ll: 0,
        lh: 0,
        lx: 0,
      }
    }
  },
  computed: {
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedNode']),
    ...mapGetters('auth', ['showLang']),
  },
  watch: {
    selectedNode(){
      this.initData();
    },
  },
  mounted: function () {
    this.initData();
  },
  methods: {
    initData: function(){
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        stationId = this.selectedNode.id;
      }else{
        groupId = this.selectedNode.id;
      }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/analyse/DeviceLeakage`, {groupId, stationId}).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'analyse', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.analyse-box {
  width: 100%;
  border: 10px solid #f0f4fa;
  border-radius: 12px;
  height: 187px;
  /* margin: 40px 0; */
}
.analyse-box-title {
  width: 100%;
  height: 50px;
  background: linear-gradient(270deg, #3682ff, #4c9eff);
  border-radius: 6px 6px 0px 0px;
  text-align: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #feffff;
  line-height: 50px;
}
.analyse-table{
  
  position: relative;
}
.analyse-table table {
  width: 100%;
  height: 117px;
}
.analyse-table table td {
  width: 115px;
  text-align: center;
  box-sizing: border-box;
  border-right: 1px solid #d0d9e9;
  border-bottom: 1px solid #d0d9e9;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.analyse-table table tr:last-child td {
  border-bottom: none;
}
.analyse-table table tr td:last-child {
  border-right: none;
}
.analyse-table table td.color1 {
  color: #2bd58c;
}
.analyse-table table td.color2 {
  color: #fec71e;
}
.analyse-table table td.color3 {
  color: #ff5d4c;
}
.analyse-table table td.color4 {
  color: #b153e7;
}
</style>