<template>
  <div class="info-container">
    <div class="info-header">
      <div class="info-header-title">
        {{showLang('com.site.info')}}
      </div>
    </div>
    <div v-if="isShowAnalysis" class="info-body">
      <div class="info-line"></div>
      <div class="info-body-count">
        <AnalysisPowerTable class="count-table" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import AnalysisPowerTable from '../analysis/PowerDevice'
import { setCookie, getCookie } from '@/plugins/cookie'
export default {
  name: 'BaseGroupInfo',
  components: {
    // AnalysisLeakageTable,
    AnalysisPowerTable,
    // StationInfoIndex,
    // DataAnalysis,
  },
  props: {
  },
  data() {
    return {
      isShowAnalysis: true,
      timer: 0,
      gainDeviceType: []
    }
  },
  computed: {
    ...mapGetters('group', ['groupPathName']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState('common', ['weeks', 'productCodes', 'deviceTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapGetters('auth', ['showLang']),
    currentStation: function () {
      if (this.selectedNode.type == this.productCodes.station) {
        return this.selectedNode;
      }
      return null;
    },
    powerBoxRoad: function () {
      if (!this.currentStation) return '';
      return this.groupPathName(this.currentStation.groupId);
    },
    onlyLeakage: function () {
      //是否只有漏电监测相关功能
      if (!this.currentStation) return false;
      return !this.currentStation.hasControl;
    },
  },
  watch: {
    selectedNode() {
    },
    cmdResultRefresh() {
    },
  },
  mounted: function () {
    if (getCookie('isShowAnalysis') == '' || getCookie('isShowAnalysis') == 'true') {
      this.isShowAnalysis = true;
    } else {
      this.isShowAnalysis = false;
    }
    this.gainDeviceType = [
      { name: this.showLang('com.state.all'), type: "all" },
      { name: this.showLang('com.device.box'), type: "powerbox" },
      { name: this.showLang('com.device.lamp'), type: "light" },
      { name: this.showLang('com.device.lightControl'), type: "lux" },
      { name: this.showLang('com.device.cable'), type: "cable" },
    ]
  },
  destroyed: function () {
  },
  methods: {
    showAnalysisBut: function () {
      this.isShowAnalysis = !this.isShowAnalysis;
      setCookie('isShowAnalysis', this.isShowAnalysis);
    },
  }
}
</script>
<style scoped>
.info-container {
  flex: none;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.info-header {
  border-bottom: solid 1px #dee5ed;
  width: 100%;
  height: 38px;
  flex: none;
  display: flex;
  align-items: center;
}
.info-header-title {
  width: 300px;
  flex: auto;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
  display: flex;
  align-items: center;
}
.header-info-region {
  float: right;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  margin-right: 35px;
}
.info-line {
  width: 100%;
  height: 6px;
  flex: none;
  background-color: #e3e9ef;
}
.info-body {
  flex: none;
  display: flex;
  flex-direction: column;
}
.info-body-count {
  flex: none;
  display: flex;
  margin: 40px 0;
}
.count-table {
  width: 50%;
  flex: auto;
  margin: 0 10px;
}
</style>