<template>
<div class="main-table">
  <table v-if="!onlyVoltage" class="table">
    <tr>
      <td colspan="8">
        <div class="table-header">
          <img :src="img.iconPowerMain" />
          <!-- {{mitem.name}} [{{new Date().long2str(mitem.fireTime)}}] -->
          {{mitem.name}} [{{new Date().long2str(mitem.lastData.time)}}]
          <div class="header-button" @click="showHistoryData">{{showLang('history.data.look')}}</div>
        </div>
      </td>
    </tr>
    <tr>
      <td class="column-header tc">{{showLang('cmd.project')}}</td>
      <td class="column-header tc">{{showLang('cmd.project.A')}}</td>
      <td class="column-header tc">{{showLang('cmd.project.B')}}</td>
      <td class="column-header tc">{{showLang('cmd.project.C')}}</td>

      <td class="column-header tc">{{showLang('cmd.project')}}</td>
      <td class="column-header tc">{{showLang('cmd.project.A')}}</td>
      <td class="column-header tc">{{showLang('cmd.project.B')}}</td>
      <td class="column-header tc">{{showLang('cmd.project.C')}}</td>
    </tr>
    <tr>
      <td class="column-header pl20"><img :src="img.statusTitleV" />{{showLang('com.lighting.V')}}</td>
      <td :class="['column-content', 'tc', ucls('a')]">{{uval('a')}}</td>
      <td :class="['column-content', 'tc', ucls('b')]">{{uval('b')}}</td>
      <td :class="['column-content', 'tc', ucls('c')]">{{uval('c')}}</td>
      <td class="column-header"><img :src="img.statusTitleQ" style="margin: 0 3px"/>{{showLang('com.data.rated.load')}}</td>
      <td :class="['column-content', 'tc']">{{mitem.content.par}} W ({{mitem.content.pah}}%)</td>
      <td :class="['column-content', 'tc']">{{mitem.content.pbr}} W ({{mitem.content.pbh}}%)</td>
      <td :class="['column-content', 'tc']">{{mitem.content.pcr}} W ({{mitem.content.pbh}}%)</td>
    </tr>
    <tr style="background: #F6F9FE;">
      <td class="column-header pl20"><img :src="img.statusTitleC" />{{showLang('com.lighting.I')}}</td>
      <td :class="['column-content', 'tc', ccls('a') ]">{{cval('a')}}</td>
      <td :class="['column-content', 'tc', ccls('b') ]">{{cval('b')}}</td>
      <td :class="['column-content', 'tc', ccls('c') ]">{{cval('c')}}</td>
      <td class="column-header"><img :src="img.statusTitlePayload" style="margin: 0 3px" />{{showLang('com.data.active.load')}}</td>
      <td :class="['column-content', 'tc', pcls('a')]">{{pval('a')}}</td>
      <td :class="['column-content', 'tc', pcls('b')]">{{pval('b')}}</td>
      <td :class="['column-content', 'tc', pcls('c')]">{{pval('c')}}</td>
    </tr>
    <tr>
      <td class="column-header pl20">
        <img :src="img.statusTitleEnergy" />{{showLang('com.data.energy.meter')}}
      </td>
      <td class="column-content" colspan="3" style="padding-left: 10px">
        {{ev}}
      </td>
      <td class="column-header pl20"><img :src="img.statusTitleF" />{{showLang('com.data.power.factor')}}</td>
      <td :class="['column-content', 'tc', fcls('a')]">{{fval('a')}}</td>
      <td :class="['column-content', 'tc', fcls('b')]">{{fval('b')}}</td>
      <td :class="['column-content', 'tc', fcls('c')]">{{fval('c')}}</td>
    </tr>
    <tr>
      <td class="column-header pl20">
        <img :src="img.statusTitleLightRate" />{{showLang('cmd.lux')}}
      </td>
      <td class="column-content" colspan="3">
        {{img.val(mitem.lastData.lux)}}
      </td>
      <td class="column-header pl20"><img :src="img.statusTitleRp" />{{showLang('com.data.reactive.power')}}</td>
      <td :class="['column-content', 'tc']">{{rpval('a')}}</td>
      <td :class="['column-content', 'tc']">{{rpval('b')}}</td>
      <td :class="['column-content', 'tc']">{{rpval('c')}}</td>
    </tr>
    <tr>
      <td class="column-header pl20">
        <img :src="img.statusTitleF" />{{showLang('com.op.locus.control')}}
      </td>
      <td class="column-content" colspan="3">
        {{mitem.lastData.ctrl >= 0 ? (mitem.lastData.ctrl == mitem.content.ctrlv ? showLang('com.op.internal') : showLang('com.op.external')) : '-'}}
      </td>
      <td class="column-header pl20">
        <img :src="img.statusTitleMaxp" />{{showLang('com.data.peak.load')}}
      </td>
      <td class="column-content tc" colspan="3">
        <template v-if="mitem.extraData!=undefined">
          {{img.val(mitem.extraData.hpv, 2)}} W <span style="margin-left: 20px">[{{img.val(mitem.extraData.hpv, 2) !='--'?new Date(mitem.extraData.hpt).format('yyyy-MM-dd HH:mm:ss'):''}}]</span>
        </template>
      </td>
    </tr>
    <ModalMainData v-model="showDataModal" :device="device" :itemInfo="historyItem" />
  </table>
  <table v-else class="table">
    <tr>
      <td colspan="5">
        <div class="table-header">
          <img :src="img.light.road.single.header.voltage" style="margin-top: -15px;" />
          <!-- 电压监测运行参数 -->
          {{mitem.name}} [{{new Date().long2str(mitem.fireTime)}}]
          <!-- <div class="header-button" @click="showHistoryData">查看历史数据</div> -->
        </div>
      </td>
    </tr>
    <tr>
      <td class="column-header tc">{{showLang('cmd.project')}}</td>
      <td class="column-header tc">{{showLang('cmd.project.A')}}</td>
      <td class="column-header tc">{{showLang('cmd.project.B')}}</td>
      <td class="column-header tc">{{showLang('cmd.project.C')}}</td>
      <td class="column-header tc">{{showLang('com.right.nav.operate')}}</td>
    </tr>
    <tr>
      <td class="column-header tc">{{mitem.name}}</td>
      <td class="column-header tc" :class="{ active: isPolice(mitem.content.uar,mitem.content.uah,mitem.content.ual,mitem.lastData.ua) }">{{img.val(mitem.lastData.ua, 1)}} V</td>
      <td class="column-header tc" :class="{ active: isPolice(mitem.content.ubr,mitem.content.ubh,mitem.content.ubl,mitem.lastData.ub) }">{{img.val(mitem.lastData.ub, 1)}} V</td>
      <td class="column-header tc" :class="{ active: isPolice(mitem.content.ucr,mitem.content.uch,mitem.content.ucl,mitem.lastData.uc) }">{{img.val(mitem.lastData.uc, 1)}} V</td>
      <td class="column-header tc"><a href="#" @click="showVoltageData(mitem)">{{showLang('history.data.look')}}</a></td>
    </tr>
   <tr>
      <td class="column-header tc" >
        {{showLang('com.op.locus.control')}}
      </td>
      <td class="column-content tc" >
        {{mitem.lastData.ctrl >= 0 ? (mitem.lastData.ctrl == mitem.content.ctrlv ? showLang('com.op.internal') : showLang('com.op.external')) : '-'}}
      </td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <ModalVoltageData v-model="showVoltageModal" :item="mitem" />
  </table>
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalMainData from './ModalMainData'
import ModalVoltageData from './ModalVoltageData'
export default {
  name: 'PowerMainTable',
  components:{
    ModalMainData,
    ModalVoltageData,
  },
  props: {
    device: {
      type: Object,
      default(){
        return {};
      }
    },
    mitem: {
      type: Object,
      default(){
        return {};
      }
    },
    energy: {
      type: Object,
      default(){
        return {};
      }
    },
  },
  data () {
    return {
      showDataModal: false,
      showVoltageModal: false,
      historyItem: {},
    }
  },
  watch: {
    mitem(){
      // console.log('power main data watch', this.mitem)
      if(!this.mitem.lastData){
        this.$set(this.mitem, 'lastData', {});
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('cmd', ['singleCmdResult']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    item: function(){
      let obj = {};
      Object.assign(obj, this.mitem.content, this.mitem.lastData);
      return obj;
    },
    onlyVoltage: function(){ //是否只有电压监测
      return !this.mitem.content.enp;
    },
    ucls: function(){
      return function(flag){
        if(this.mitem.lastData.utv != 1)return '';
        let u = -1;
        if(this.mitem.content.showe && this.energy && this.energy.lastData){
          u = this.energy.lastData[`u${flag}`];
        }else{
          if(!this.mitem.lastData)return '';
          u = this.mitem.lastData[`u${flag}`];
        }
        if(u == -1)return '';
        let low = this.mitem.content[`u${flag}r`] * this.mitem.content[`u${flag}l`] / 100;
        let high = this.mitem.content[`u${flag}r`] * this.mitem.content[`u${flag}h`] / 100;
        if(u > high) return 'alarm-high';
        else if(u < low) return 'alarm-low';
        else return '';
      }
    },
    uval: function(){
      return function(flag){
        let u = -1;
        if(this.mitem.content.showe && this.energy && this.energy.lastData){
          u = this.energy.lastData[`u${flag}`];
        }else{
          if(!this.mitem.lastData)return '-';
          u = this.mitem.lastData[`u${flag}`];
        }
        if(u < 0)return '-';
        let low = this.img.val(this.mitem.content[`u${flag}r`] * this.mitem.content[`u${flag}l`] / 100,0);
        let high = this.img.val(this.mitem.content[`u${flag}r`] * this.mitem.content[`u${flag}h`] / 100,0);
        u = this.img.val(u, 1);
        return `${u}(${low} - ${high}) V`
      }
    },
    ev: function(){
      if(this.mitem.content.showe && this.energy && this.energy.lastData)return `${this.img.val(this.energy.lastData.ae, 2)} KWh (${this.energy.content.rate}倍)`;
      if(this.mitem && this.mitem.lastData)return `${this.img.val(this.mitem.lastData.e, 2)} KWh`;
      return '-'
    },
    ccls: function(){
      //mitem.lastData.ctv == 1 && mitem.lastData.ca >= 0 && mitem.lastData.ca > mitem.content.car * mitem.content.cah / 100 ? 'alarm-high':'', mitem.lastData.tv == 1 && mitem.lastData.ca >= 0 && mitem.lastData.ca < mitem.content.car * mitem.content.cal / 100 ? 'alarm-low':''
      return function(flag){
        if(this.mitem.lastData.ctv != 1)return '';
        let c = -1;
        if(this.mitem.content.showe && this.energy && this.energy.lastData){
          c = this.energy.lastData[`c${flag}`];
        }else{
          if(!this.mitem.lastData)return '';
          c = this.mitem.lastData[`c${flag}`];
        }
        if(c == -1)return '';
        let low = this.mitem.content[`c${flag}r`] * this.mitem.content[`c${flag}l`] / 100;
        let high = this.mitem.content[`c${flag}r`] * this.mitem.content[`c${flag}h`] / 100;
        if(c > high) return 'alarm-high';
        else if(c < low) return 'alarm-low';
        else return '';
      }
    },
    cval: function(){
      //{{img.val(mitem.lastData.ca, 1)}} ({{img.val(mitem.content.car * mitem.content.cal / 100, 1)}} - {{img.val(mitem.content.car * mitem.content.cah / 100, 1)}}) A
      return function(flag){
        let c = -1;
        if(this.mitem.content.showe && this.energy && this.energy.lastData){
          c = this.energy.lastData[`c${flag}`];
        }else{
          if(!this.mitem.lastData)return '-';
          c = this.mitem.lastData[`c${flag}`];
        }
        if(c < 0)return '-';
        let low = this.img.val(this.mitem.content[`c${flag}r`] * this.mitem.content[`c${flag}l`] / 100, 1);
        let high = this.img.val(this.mitem.content[`c${flag}r`] * this.mitem.content[`c${flag}h`] / 100, 1);
        c = this.img.val(c, 1);
        return `${c}(${low} - ${high}) A`
      }
    },
    pcls: function(){
      //mitem.lastData.ptv == 1 && mitem.lastData.pa >= 0 && mitem.lastData.pa > mitem.content.par * mitem.content.pah / 100 ? 'alarm-high':''
      return function(flag){
        if(this.mitem.lastData.ptv != 1)return '';
        let p = -1;
        if(this.mitem.content.showe && this.energy && this.energy.lastData){
          p = this.energy.lastData[`p${flag}`];
        }else{
          if(!this.mitem.lastData)return '';
          p = this.mitem.lastData[`p${flag}`];
        }
        if(p == -1)return '';
        let high = this.mitem.content[`p${flag}r`] * this.mitem.content[`p${flag}h`] / 100;
        if(p > high) return 'alarm-high';
        else return '';
      }
    },
    pval: function(){
      //({{img.val(mitem.lastData.pc * 100 / mitem.content.pcr, 1)}} %)
      return function(flag){
        let p = -1;
        if(this.mitem.content.showe && this.energy && this.energy.lastData){
          p = this.energy.lastData[`ap${flag}`];
        }else{
          if(!this.mitem.lastData)return '-';
          p = this.mitem.lastData[`p${flag}`];
        }
        if(p < 0)return '-';
        let high = this.img.val(p * 100 / this.mitem.content[`p${flag}r`], 1);
        p = this.img.val(p, 1);
        return `${p}W(${high >-1 ? high : 0}%)`
      }
    },
    fcls: function(){
      return function(flag){
        if(this.mitem.lastData.ptv != 1)return '';
        let f = -1;
        if(this.mitem.content.showe && this.energy && this.energy.lastData){
          f = this.energy.lastData[`f${flag}`];
        }else{
          if(!this.mitem.lastData)return '';
          f = this.mitem.lastData[`f${flag}`];
        }
        if(f == -1)return '';
        let high = this.mitem.content[`p${flag}f`];
        if(f < high) return 'alarm-high';
        else return '';
      }
    },
    fval: function(){
      return function(flag){
        let f = -1;
        if(this.mitem.content.showe && this.energy && this.energy.lastData){
          f = this.energy.lastData[`f${flag}`];
        }else{
          if(!this.mitem.lastData)return '-';
          f = this.mitem.lastData[`f${flag}`];
        }
        if(f < 0)return '-';
        let high = this.mitem.content[`p${flag}f`];
        f = this.img.val(f, 3);
        return `${f}(${high})`
      }
    },
    rpval: function(){
      return function(flag){
        if(this.mitem.content.showe && this.energy && this.energy.lastData){
          return this.energy.lastData[`rp${flag}`] + 'Var';
        }else{
          let u = this.mitem.lastData[`u${flag}`];
          let c = this.mitem.lastData[`c${flag}`];
          let p = this.mitem.lastData[`p${flag}`];
          if(!u || !c || !p)return '--';
          if(u < 0 || c < 0 ||  p< 0)return '-';
          // console.log('u, c, p =', u, c, p, (u * c - p).toFixed(2))
          let sp = Math.sqrt((u * c) ** 2 - p ** 2);
          return sp.toFixed(2) + 'Var';
        }
      }
    },
  },
  created: function(){
    if(!this.mitem.lastData){
      this.$set(this.mitem, 'lastData', {});
    }
    
    // console.log('power main data mounted', this.mitem)
  },
  mounted: function(){
    // console.log('power main data mounted', this.mitem, this.mitem.content.showe)
    // if(!this.mitem.lastData){
    //   this.$set(this.mitem, 'lastData', {});
    //   // this.mitem.lastData = {};
    // }
    window.eventBus.$on('paramRealDataComing', params => {
      // console.log('window get home load event =======================', params, this.mitem);
      if(params.paramId == this.mitem.id){
        this.$set(this.mitem, 'lastData', params.data);
        this.$set(this.mitem, 'extraData', params.extra);
      }
      if(this.energy && params.paramId == this.energy.id){
        this.$set(this.energy, 'lastData', params.data);
        this.$set(this.energy, 'extraData', params.extra);
      }
      // this.$set(this.mitem, 'fireTime', params.time);

      // console.log('window get home load event =======================', params, this.mitem.lastData);
    })
    // console.log('power main mounted', this.mitem, this.energy);
      // console.log('window remove home load event +++++++++++++++++++++++++');
  },
  destroyed: function(){
      // console.log('window remove home load event --------------------------');
    window.eventBus.$off('paramRealDataComing');
  },
  methods: {
    np: function(u, c, p){
      if(!u || !c || !p)return '--';
      if(u < 0 || c < 0 ||  p< 0)return '-';
      return (u * c - p).toFixed(2);
    },
    showHistoryData: function(){
      this.historyItem = {
        energy: this.energy,
        main: this.mitem,
        showe: this.mitem.content.showe
      };
      this.showDataModal = true;
    },
    showVoltageData: function () {
      // this.editItem = el;
      this.showVoltageModal = true;
    },
    isPolice(r, h, l, el) {
      if (el == "--") return false;
      let upper = r * h / 100;
      let lower = r * l / 100;
      if (upper < el || lower > el) {
        return true;
      }
      return false;
    },
    panelClick: function(){
      return false;
    }
  }
}
</script>
<style scoped>
.main-table{
  width: 1110px;
  /* height: 305px; */
  background: #F0F4FA;
  border-radius: 12px;
  padding: 10px;
}
.table-header{
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 1090px;
  height: 50px;
  line-height: 47px;
  text-align: center;
  /* background: rgba(10, 26, 68, 0.8); */
  background: linear-gradient(270deg, #3682FF, #4C9EFF);
  /* box-shadow: inset 0px 0px 24px 0px #04559D; */
  /* border-top: solid 1px #0a60b0; */
  /* border-bottom: solid 1px #0a60b0; */
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  position: relative;
  /* color: #52FBFE; */
  /* border: solid 1px black; */
}
.header-button{
  position: absolute;
  top: 10px;
  right: 10px;
  /* border: 1px solid #FFFFFF; */
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3880FC;
  width: 103px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 6px;
  cursor: pointer;
}
.table{
  border-collapse: separate;
  /* border-radius: 12px; */
  border-spacing: 0;
}
.table tr:first-child td:first-child {
  border-top-left-radius: 12px;
}
.table tr:first-child td:last-child {
  border-top-right-radius: 12px;
}
.table tr:last-child td:first-child {
  border-bottom-left-radius: 12px;
}
.table tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}
.table-header img{
  vertical-align: middle;
  margin-right: 3px;
}
.column-header{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* color: #FFFFFF; */
  color: #0D3A6A;
  opacity: 0.8;
  border: solid 1px white;
  /* text-align: left; */
  /* padding-left: 20px; */
  height: 39px;
  width: 130px;
  /* border-collapse: collapse; */
  /* background: #183992; */
  background: #C8DAEF;
}
.column-header img{
  vertical-align: middle;
  margin-right: 10px;
}
.column-content{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* color: #52FBFE; */
  color: #0D3A6A;
  background: #E8EFF7;
  /* background: #2c9ef55e; */
  border: solid 1px white;
  /* text-align: left; */
  /* padding-left: 40px; */
  height: 32px;
  width: 130px;
  /* border: solid 1px red; */
}
</style>